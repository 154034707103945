import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
export class CalendarNavComponent {
   label: string;
   isLabelClickable: boolean = false;
   showLeftNav: boolean = true;
   showLeftSecondaryNav: boolean = false;
   showRightNav: boolean = true;
   showRightSecondaryNav: boolean = false;
   leftNavDisabled: boolean = false;
   leftSecondaryNavDisabled: boolean = false;
   rightNavDisabled: boolean = false;
   rightSecondaryNavDisabled: boolean = false;
   showGoToCurrent: boolean = true;
    theme: string;

   onLeftNav: EventEmitter<null> = new EventEmitter();
   onLeftSecondaryNav: EventEmitter<null> = new EventEmitter();
   onRightNav: EventEmitter<null> = new EventEmitter();
   onRightSecondaryNav: EventEmitter<null> = new EventEmitter();
   onLabelClick: EventEmitter<null> = new EventEmitter();
   onGoToCurrent: EventEmitter<null> = new EventEmitter();
/**
 * @return {?}
 */
leftNavClicked() {
    this.onLeftNav.emit();
  }
/**
 * @return {?}
 */
leftSecondaryNavClicked() {
    this.onLeftSecondaryNav.emit();
  }
/**
 * @return {?}
 */
rightNavClicked() {
    this.onRightNav.emit();
  }
/**
 * @return {?}
 */
rightSecondaryNavClicked() {
    this.onRightSecondaryNav.emit();
  }
/**
 * @return {?}
 */
labelClicked() {
    this.onLabelClick.emit();
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'dp-calendar-nav',
  template: `
    <div class="dp-calendar-nav-container">
      <div class="dp-nav-header">
        <span [hidden]="isLabelClickable"
              [attr.data-hidden]="isLabelClickable"
              [innerText]="label">
        </span>
        <button type="button"
                class="dp-nav-header-btn"
                [hidden]="!isLabelClickable"
                [attr.data-hidden]="!isLabelClickable"
                (click)="labelClicked()"
                [innerText]="label">
        </button>
      </div>

      <div class="dp-nav-btns-container">
        <div class="dp-calendar-nav-container-left">
          <button type="button"
                  class="dp-calendar-secondary-nav-left"
                  *ngIf="showLeftSecondaryNav"
                  [disabled]="leftSecondaryNavDisabled"
                  (click)="leftSecondaryNavClicked()">
          </button>
          <button type="button"
                  class="dp-calendar-nav-left"
                  [hidden]="!showLeftNav"
                  [attr.data-hidden]="!showLeftNav"
                  [disabled]="leftNavDisabled"
                  (click)="leftNavClicked()">
          </button>
        </div>
        <button type="button"
                class="dp-current-location-btn"
                *ngIf="showGoToCurrent"
                (click)="onGoToCurrent.emit()">
        </button>
        <div class="dp-calendar-nav-container-right">
          <button type="button"
                  class="dp-calendar-nav-right"
                  [hidden]="!showRightNav"
                  [attr.data-hidden]="!showRightNav"
                  [disabled]="rightNavDisabled"
                  (click)="rightNavClicked()">
          </button>
          <button type="button"
                  class="dp-calendar-secondary-nav-right"
                  *ngIf="showRightSecondaryNav"
                  [disabled]="rightSecondaryNavDisabled"
                  (click)="rightSecondaryNavClicked()">
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    dp-calendar-nav .dp-calendar-nav-container {
      position: relative;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 25px;
      border: 1px solid #000000;
      border-bottom: none;
    }
    dp-calendar-nav .dp-nav-date-btn {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 25px;
      border: 1px solid #000000;
      border-bottom: none;
    }
    dp-calendar-nav .dp-nav-btns-container {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 5px;
      display: inline-block;
      direction: ltr;
    }
    dp-calendar-nav .dp-calendar-nav-container-left,
    dp-calendar-nav .dp-calendar-nav-container-right {
      display: inline-block;
    }
    dp-calendar-nav .dp-calendar-nav-left,
    dp-calendar-nav .dp-calendar-nav-right,
    dp-calendar-nav .dp-calendar-secondary-nav-left,
    dp-calendar-nav .dp-calendar-secondary-nav-right {
      position: relative;
      width: 16px;
      cursor: pointer;
    }
    dp-calendar-nav .dp-calendar-nav-left,
    dp-calendar-nav .dp-calendar-nav-right {
      line-height: 0;
    }
    dp-calendar-nav .dp-calendar-nav-left::before,
    dp-calendar-nav .dp-calendar-nav-right::before {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
    dp-calendar-nav .dp-calendar-secondary-nav-left,
    dp-calendar-nav .dp-calendar-secondary-nav-right {
      padding: 0;
    }
    dp-calendar-nav .dp-calendar-secondary-nav-left::before,
    dp-calendar-nav .dp-calendar-secondary-nav-right::before,
    dp-calendar-nav .dp-calendar-secondary-nav-left::after,
    dp-calendar-nav .dp-calendar-secondary-nav-right::after {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
    dp-calendar-nav .dp-calendar-secondary-nav-left::before,
    dp-calendar-nav .dp-calendar-secondary-nav-right::before {
      right: -10px;
    }
    dp-calendar-nav .dp-calendar-secondary-nav-right {
      left: initial;
      right: 5px;
    }
    dp-calendar-nav .dp-calendar-nav-left::before {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
    }
    dp-calendar-nav .dp-calendar-secondary-nav-left::before,
    dp-calendar-nav .dp-calendar-secondary-nav-left::after {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
    }
    dp-calendar-nav .dp-calendar-secondary-nav-left::before {
      right: -10px;
    }
    dp-calendar-nav .dp-nav-header {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 5px;
      display: inline-block;
      font-size: 13px;
    }
    dp-calendar-nav .dp-nav-header-btn {
      cursor: pointer;
    }
    dp-calendar-nav .dp-current-location-btn {
      position: relative;
      top: -1px;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.6);
      outline: none;
      border-radius: 50%;
      -webkit-box-shadow: inset 0 0 0 3px #FFFFFF;
              box-shadow: inset 0 0 0 3px #FFFFFF;
      cursor: pointer;
    }
    dp-calendar-nav .dp-current-location-btn:hover {
      background: #000000;
    }
    dp-calendar-nav.dp-material .dp-calendar-nav-container {
      height: 30px;
      border: 1px solid #E0E0E0;
    }
    dp-calendar-nav.dp-material .dp-calendar-nav-left,
    dp-calendar-nav.dp-material .dp-calendar-nav-right,
    dp-calendar-nav.dp-material .dp-calendar-secondary-nav-left,
    dp-calendar-nav.dp-material .dp-calendar-secondary-nav-right {
      border: none;
      background: #FFFFFF;
      outline: none;
      font-size: 16px;
      padding: 0;
    }
    dp-calendar-nav.dp-material .dp-calendar-secondary-nav-left,
    dp-calendar-nav.dp-material .dp-calendar-secondary-nav-right {
      width: 20px;
    }
    dp-calendar-nav.dp-material .dp-nav-header-btn {
      height: 20px;
      width: 80px;
      border: none;
      background: #FFFFFF;
      outline: none;
    }
    dp-calendar-nav.dp-material .dp-nav-header-btn:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    dp-calendar-nav.dp-material .dp-nav-header-btn:active {
      background: rgba(0, 0, 0, 0.1);
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'label': [{ type: Input },],
'isLabelClickable': [{ type: Input },],
'showLeftNav': [{ type: Input },],
'showLeftSecondaryNav': [{ type: Input },],
'showRightNav': [{ type: Input },],
'showRightSecondaryNav': [{ type: Input },],
'leftNavDisabled': [{ type: Input },],
'leftSecondaryNavDisabled': [{ type: Input },],
'rightNavDisabled': [{ type: Input },],
'rightSecondaryNavDisabled': [{ type: Input },],
'showGoToCurrent': [{ type: Input },],
'theme': [{ type: HostBinding, args: ['class', ] },{ type: Input },],
'onLeftNav': [{ type: Output },],
'onLeftSecondaryNav': [{ type: Output },],
'onRightNav': [{ type: Output },],
'onRightSecondaryNav': [{ type: Output },],
'onLabelClick': [{ type: Output },],
'onGoToCurrent': [{ type: Output },],
};
}

function CalendarNavComponent_tsickle_Closure_declarations() {
/** @type {?} */
CalendarNavComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CalendarNavComponent.ctorParameters;
/** @type {?} */
CalendarNavComponent.propDecorators;
/** @type {?} */
CalendarNavComponent.prototype.label;
/** @type {?} */
CalendarNavComponent.prototype.isLabelClickable;
/** @type {?} */
CalendarNavComponent.prototype.showLeftNav;
/** @type {?} */
CalendarNavComponent.prototype.showLeftSecondaryNav;
/** @type {?} */
CalendarNavComponent.prototype.showRightNav;
/** @type {?} */
CalendarNavComponent.prototype.showRightSecondaryNav;
/** @type {?} */
CalendarNavComponent.prototype.leftNavDisabled;
/** @type {?} */
CalendarNavComponent.prototype.leftSecondaryNavDisabled;
/** @type {?} */
CalendarNavComponent.prototype.rightNavDisabled;
/** @type {?} */
CalendarNavComponent.prototype.rightSecondaryNavDisabled;
/** @type {?} */
CalendarNavComponent.prototype.showGoToCurrent;
/** @type {?} */
CalendarNavComponent.prototype.theme;
/** @type {?} */
CalendarNavComponent.prototype.onLeftNav;
/** @type {?} */
CalendarNavComponent.prototype.onLeftSecondaryNav;
/** @type {?} */
CalendarNavComponent.prototype.onRightNav;
/** @type {?} */
CalendarNavComponent.prototype.onRightSecondaryNav;
/** @type {?} */
CalendarNavComponent.prototype.onLabelClick;
/** @type {?} */
CalendarNavComponent.prototype.onGoToCurrent;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
