import {Injectable} from '@angular/core';
import * as momentNs from 'jalali-moment';
import {Moment} from 'jalali-moment';
import {UtilsService} from '../common/services/utils/utils.service';
import {ITimeSelectConfig, ITimeSelectConfigInternal} from './time-select-config.model';
const /** @type {?} */ moment = momentNs;

export type TimeUnit = 'hour' | 'minute' | 'second';
export const /** @type {?} */ FIRST_PM_HOUR = 12;
export class TimeSelectService {
  readonly DEFAULT_CONFIG: ITimeSelectConfigInternal = /** @type {?} */(( <ITimeSelectConfigInternal> {
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: false,
    timeSeparator: ':',
    locale: 'fa'
  }));
/**
 * @param {?} utilsService
 */
constructor(private utilsService: UtilsService) {
  }
/**
 * @param {?} config
 * @return {?}
 */
getConfig(config: ITimeSelectConfig): ITimeSelectConfigInternal {
    const /** @type {?} */ timeConfigs = {
      maxTime: this.utilsService.onlyTime( /** @type {?} */((<Moment>(config && config.maxTime)))),
      minTime: this.utilsService.onlyTime( /** @type {?} */((<Moment>(config && config.minTime))))
    };

    const /** @type {?} */ _config = /** @type {?} */(( <ITimeSelectConfigInternal>{
      ...this.DEFAULT_CONFIG,
      ...this.utilsService.clearUndefined(config),
      ...timeConfigs
    }));

    // moment.locale(_config.locale);

    return _config;
  }
/**
 * @param {?} config
 * @return {?}
 */
getTimeFormat(config: ITimeSelectConfigInternal): string {
    return (config.showTwentyFourHours ? config.hours24Format : config.hours12Format)
      + config.timeSeparator + config.minutesFormat
      + (config.showSeconds ? (config.timeSeparator + config.secondsFormat) : '')
      + (config.showTwentyFourHours ? '' : ' ' + config.meridiemFormat);
  }
/**
 * @param {?} config
 * @param {?} t
 * @return {?}
 */
getHours(config: ITimeSelectConfigInternal, t: Moment | null): string {
    const /** @type {?} */ time = t || moment();
    return time && time.format(config.showTwentyFourHours ? config.hours24Format : config.hours12Format);
  }
/**
 * @param {?} config
 * @param {?} t
 * @return {?}
 */
getMinutes(config: ITimeSelectConfigInternal, t: Moment | null): string {
    const /** @type {?} */ time = t || moment();
    return time && time.format(config.minutesFormat);
  }
/**
 * @param {?} config
 * @param {?} t
 * @return {?}
 */
getSeconds(config: ITimeSelectConfigInternal, t: Moment | null): string {
    const /** @type {?} */ time = t || moment();
    return time && time.format(config.secondsFormat);
  }
/**
 * @param {?} config
 * @param {?} time
 * @return {?}
 */
getMeridiem(config: ITimeSelectConfigInternal, time: Moment): string {
    if (config.locale) {
      time.locale(config.locale);
    }
    return time && time.format(config.meridiemFormat);
  }
/**
 * @param {?} config
 * @param {?} time
 * @param {?} unit
 * @return {?}
 */
decrease(config: ITimeSelectConfigInternal, time: Moment, unit: TimeUnit): Moment {
    let /** @type {?} */ amount: number = 1;
    switch (unit) {
      case 'minute':
        amount = config.minutesInterval;
        break;
      case 'second':
        amount = config.secondsInterval;
        break;
    }
    return time.clone().subtract(amount, unit);
  }
/**
 * @param {?} config
 * @param {?} time
 * @param {?} unit
 * @return {?}
 */
increase(config: ITimeSelectConfigInternal, time: Moment, unit: TimeUnit): Moment {
    let /** @type {?} */ amount: number = 1;
    switch (unit) {
      case 'minute':
        amount = config.minutesInterval;
        break;
      case 'second':
        amount = config.secondsInterval;
        break;
    }
    return time.clone().add(amount, unit);
  }
/**
 * @param {?} time
 * @return {?}
 */
toggleMeridiem(time: Moment): Moment {
    if (time.hours() < FIRST_PM_HOUR) {
      return time.clone().add(12, 'hour');
    } else {
      return time.clone().subtract(12, 'hour');
    }
  }
/**
 * @param {?} config
 * @param {?} time
 * @param {?} unit
 * @return {?}
 */
shouldShowDecrease(config: ITimeSelectConfigInternal, time: Moment, unit: TimeUnit): boolean {
    if (!config.min && !config.minTime) {
      return true;
    }

    const /** @type {?} */ newTime = this.decrease(config, time, unit);

    return (!config.min || config.min.isSameOrBefore(newTime))
      && (!config.minTime || config.minTime.isSameOrBefore(this.utilsService.onlyTime(newTime)));
  }
/**
 * @param {?} config
 * @param {?} time
 * @param {?} unit
 * @return {?}
 */
shouldShowIncrease(config: ITimeSelectConfigInternal, time: Moment, unit: TimeUnit): boolean {
    if (!config.max && !config.maxTime) {
      return true;
    }

    const /** @type {?} */ newTime = this.increase(config, time, unit);

    return (!config.max || config.max.isSameOrAfter(newTime))
      && (!config.maxTime || config.maxTime.isSameOrAfter(this.utilsService.onlyTime(newTime)));
  }
/**
 * @param {?} config
 * @param {?} time
 * @return {?}
 */
shouldShowToggleMeridiem(config: ITimeSelectConfigInternal, time: Moment): boolean {
    if (!config.min && !config.max && !config.minTime && !config.maxTime) {
      return true;
    }
    const /** @type {?} */ newTime = this.toggleMeridiem(time);
    return (!config.max || config.max.isSameOrAfter(newTime))
      && (!config.min || config.min.isSameOrBefore(newTime))
      && (!config.maxTime || config.maxTime.isSameOrAfter(this.utilsService.onlyTime(newTime)))
      && (!config.minTime || config.minTime.isSameOrBefore(this.utilsService.onlyTime(newTime)));
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: UtilsService, },
];
}

function TimeSelectService_tsickle_Closure_declarations() {
/** @type {?} */
TimeSelectService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TimeSelectService.ctorParameters;
/** @type {?} */
TimeSelectService.prototype.DEFAULT_CONFIG;
/** @type {?} */
TimeSelectService.prototype.utilsService;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
