import {UtilsService} from '../common/services/utils/utils.service';
import {IDatePickerDirectiveConfig} from './date-picker-directive-config.model';
import {ElementRef, Injectable} from '@angular/core';
export class DatePickerDirectiveService {
/**
 * @param {?} utilsService
 */
constructor(public utilsService: UtilsService) {
  }
/**
 * @param {?} attachTo
 * @param {?} baseElement
 * @return {?}
 */
convertToHTMLElement(attachTo: ElementRef | string, baseElement: HTMLElement): HTMLElement {
    if (typeof attachTo === 'string') {
      return this.utilsService.closestParent(baseElement, attachTo);
    } else if (attachTo) {
      return attachTo.nativeElement;
    }

    return undefined;
  }
/**
 * @param {?=} config
 * @param {?=} baseElement
 * @param {?=} attachTo
 * @return {?}
 */
getConfig(config: IDatePickerDirectiveConfig = {},
            baseElement?: ElementRef,
            attachTo?: ElementRef | string): IDatePickerDirectiveConfig {
    const /** @type {?} */ _config: IDatePickerDirectiveConfig = {...config};
    _config.hideInputContainer = true;

    let /** @type {?} */ native;

    if (config.inputElementContainer) {
      native = this.utilsService.getNativeElement(config.inputElementContainer);
    } else {
      native = baseElement ? baseElement.nativeElement : null;
    }

    if (native) {
      _config.inputElementContainer = attachTo
        ? this.convertToHTMLElement(attachTo, native)
        : native;
    }

    return _config;
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: UtilsService, },
];
}

function DatePickerDirectiveService_tsickle_Closure_declarations() {
/** @type {?} */
DatePickerDirectiveService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DatePickerDirectiveService.ctorParameters;
/** @type {?} */
DatePickerDirectiveService.prototype.utilsService;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
