import { NgModule } from '@angular/core';
import { AlifeFileToBase64Directive } from './alife-file-to-base64.directive';

@NgModule({
  imports: [
  ],
  declarations: [AlifeFileToBase64Directive],
  exports: [AlifeFileToBase64Directive]
})
export class AlifeFileToBase64Module { }
