import * as momentNs from 'jalali-moment';
const /** @type {?} */ moment = momentNs;
import {IDate} from '../common/models/date.model';
import {DomHelper} from '../common/services/dom-appender/dom-appender.service';
import {UtilsService} from '../common/services/utils/utils.service';
import {CalendarMode} from '../common/types/calendar-mode';
import {ECalendarMode} from '../common/types/calendar-mode-enum';
import {CalendarValue} from '../common/types/calendar-value';
import {ECalendarValue} from '../common/types/calendar-value-enum';
import {SingleCalendarValue} from '../common/types/single-calendar-value';
import {IDayCalendarConfig} from '../day-calendar/day-calendar-config.model';
import {DayCalendarComponent} from '../day-calendar/day-calendar.component';
import {DayCalendarService} from '../day-calendar/day-calendar.service';
import {IDayTimeCalendarConfig} from '../day-time-calendar/day-time-calendar-config.model';
import {DayTimeCalendarService} from '../day-time-calendar/day-time-calendar.service';
import {ITimeSelectConfig} from '../time-select/time-select-config.model';
import {TimeSelectComponent} from '../time-select/time-select.component';
import {TimeSelectService} from '../time-select/time-select.service';
import {IDatePickerConfig, IDatePickerConfigInternal} from './date-picker-config.model';
import {IDpDayPickerApi} from './date-picker.api';
import {DatePickerService} from './date-picker.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Moment, unitOfTime} from 'jalali-moment';
import {DateValidator} from '../common/types/validator.type';
import {MonthCalendarComponent} from '../month-calendar/month-calendar.component';
import {DayTimeCalendarComponent} from '../day-time-calendar/day-time-calendar.component';
import {INavEvent} from '../common/models/navigation-event.model';
export class DatePickerComponent implements OnChanges,
                                            OnInit,
                                            AfterViewInit,
                                            ControlValueAccessor,
                                            Validator,
                                            OnDestroy {
  isInitialized: boolean = false;
   config: IDatePickerConfig;
   mode: CalendarMode = 'day';
   placeholder: string = '';
   disabled: boolean = false;
   displayDate: SingleCalendarValue;
    theme: string;
   minDate: SingleCalendarValue;
   maxDate: SingleCalendarValue;
   minTime: SingleCalendarValue;
   maxTime: SingleCalendarValue;

   open = new EventEmitter<void>();
   close = new EventEmitter<void>();
   onChange = new EventEmitter<CalendarValue>();
   onGoToCurrent: EventEmitter<void> = new EventEmitter();
   onLeftNav: EventEmitter<INavEvent> = new EventEmitter();
   onRightNav: EventEmitter<INavEvent> = new EventEmitter();

   calendarContainer: ElementRef;
   dayCalendarRef: DayCalendarComponent;
   monthCalendarRef: MonthCalendarComponent;
   dayTimeCalendarRef: DayTimeCalendarComponent;
   timeSelectRef: TimeSelectComponent;

  componentConfig: IDatePickerConfigInternal;
  dayCalendarConfig: IDayCalendarConfig;
  dayTimeCalendarConfig: IDayTimeCalendarConfig;
  timeSelectConfig: ITimeSelectConfig;
  _areCalendarsShown: boolean = false;
  hideStateHelper: boolean = false;
  _selected: Moment[] = [];
  inputValue: CalendarValue;
  inputValueType: ECalendarValue;
  isFocusedTrigger: boolean = false;
  _currentDateView: Moment;
  inputElementValue: string;
  calendarWrapper: HTMLElement;
  appendToElement: HTMLElement;
  inputElementContainer: HTMLElement;
  popupElem: HTMLElement;
  handleInnerElementClickUnlisteners: Function[] = [];
  globalListnersUnlisteners: Function[] = [];
  validateFn: DateValidator;
  api: IDpDayPickerApi = {
    open: this.showCalendars.bind(this),
    close: this.hideCalendar.bind(this),
    moveCalendarTo: this.moveCalendarTo.bind(this)
  };
/**
 * @param {?} selected
 * @return {?}
 */
set selected(selected: Moment[]) {
    this._selected = selected;
    this.inputElementValue = ( /** @type {?} */((<string[]>this.utilsService
      .convertFromMomentArray(this.componentConfig.format, selected, ECalendarValue.StringArr, this.componentConfig.locale))))
      .join(' | ');
    const /** @type {?} */ val = this.processOnChangeCallback(selected);
    this.onChangeCallback(val, false);
    this.onChange.emit(val);
  }
/**
 * @return {?}
 */
get selected(): Moment[] {
    return this._selected;
  }
/**
 * @return {?}
 */
get areCalendarsShown(): boolean {
    return this._areCalendarsShown;
  }
/**
 * @return {?}
 */
get openOnFocus(): boolean {
    return this.componentConfig.openOnFocus;
  }
/**
 * @return {?}
 */
get openOnClick(): boolean {
    return this.componentConfig.openOnClick;
  }
/**
 * @param {?} value
 * @return {?}
 */
set areCalendarsShown(value: boolean) {
    if (value) {
      this.startGlobalListeners();
      this.domHelper.appendElementToPosition({
        container: this.appendToElement,
        element: this.calendarWrapper,
        anchor: this.inputElementContainer,
        dimElem: this.popupElem,
        drops: this.componentConfig.drops,
        opens: this.componentConfig.opens
      });
    } else {
      this.stopGlobalListeners();
      this.dayPickerService.pickerClosed();
    }

    this._areCalendarsShown = value;
  }
/**
 * @return {?}
 */
get currentDateView(): Moment {
    return this._currentDateView;
  }
/**
 * @param {?} date
 * @return {?}
 */
set currentDateView(date: Moment) {
    this._currentDateView = date;

    if (this.dayCalendarRef) {
      this.dayCalendarRef.moveCalendarTo(date);
    }

    if (this.monthCalendarRef) {
      this.monthCalendarRef.moveCalendarTo(date);
    }

    if (this.dayTimeCalendarRef) {
      this.dayTimeCalendarRef.moveCalendarTo(date);
    }
  }
/**
 * @param {?} dayPickerService
 * @param {?} domHelper
 * @param {?} elemRef
 * @param {?} renderer
 * @param {?} utilsService
 * @param {?} cd
 */
constructor(private readonly dayPickerService: DatePickerService,
private readonly domHelper: DomHelper,
private readonly elemRef: ElementRef,
private readonly renderer: Renderer2,
private readonly utilsService: UtilsService,
public readonly cd: ChangeDetectorRef) {
  }
/**
 * @return {?}
 */
onClick() {
    if (!this.openOnClick) {
      return;
    }

    if (!this.isFocusedTrigger && !this.disabled) {
      this.hideStateHelper = true;
      if (!this.areCalendarsShown) {
        this.showCalendars();
      }
    }
  }
/**
 * @return {?}
 */
onBodyClick() {
    if (this.componentConfig.hideOnOutsideClick) {
      if (!this.hideStateHelper && this.areCalendarsShown) {
        this.hideCalendar();
      }

      this.hideStateHelper = false;
    }
  }
/**
 * @return {?}
 */
onScroll() {
    if (this.areCalendarsShown) {
      this.domHelper.setElementPosition({
        container: this.appendToElement,
        element: this.calendarWrapper,
        anchor: this.inputElementContainer,
        dimElem: this.popupElem,
        drops: this.componentConfig.drops,
        opens: this.componentConfig.opens
      });
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
writeValue(value: CalendarValue): void {
    this.inputValue = value;

    if (value || value === '') {
      this.selected = this.utilsService
        .convertToMomentArray(value, this.componentConfig.format, this.componentConfig.allowMultiSelect, this.componentConfig.locale);
      this.currentDateView = this.selected.length
        ? this.utilsService.getDefaultDisplayDate(null, this.selected, this.componentConfig.allowMultiSelect,
          this.componentConfig.min, this.componentConfig.locale)
        : this.currentDateView;
      this.init();
    } else {
      this.selected = [];
    }

    this.cd.markForCheck();
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
/**
 * @param {?} _
 * @param {?} changedByInput
 * @return {?}
 */
onChangeCallback(_: any, changedByInput: boolean) {
  };
/**
 * @param {?} fn
 * @return {?}
 */
registerOnTouched(fn: any): void {
  }
/**
 * @param {?} formControl
 * @return {?}
 */
validate(formControl: FormControl): ValidationErrors {
    return this.validateFn(formControl.value);
  }
/**
 * @param {?} selected
 * @return {?}
 */
processOnChangeCallback(selected: Moment[] | string): CalendarValue {
    if (typeof selected === 'string') {
      return selected;
    } else {
      return this.utilsService.convertFromMomentArray(
        this.componentConfig.format,
        selected,
        this.componentConfig.returnedValueType || this.inputValueType,
        this.componentConfig.locale
      );
    }
  }
/**
 * @return {?}
 */
initValidators() {
    this.validateFn = this.utilsService.createValidator(
      {
        minDate: this.minDate,
        maxDate: this.maxDate,
        minTime: this.minTime,
        maxTime: this.maxTime
      }, this.componentConfig.format, this.mode, this.componentConfig.locale);
    this.onChangeCallback(this.processOnChangeCallback(this.selected), false);
  }
/**
 * @return {?}
 */
ngOnInit() {
    this.isInitialized = true;
    this.init();
    this.initValidators();
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {
    if (this.isInitialized) {
      const {minDate, maxDate, minTime, maxTime} = changes;

      this.init();

      if (minDate || maxDate || minTime || maxTime) {
        this.initValidators();
      }
    }
  }
/**
 * @return {?}
 */
ngAfterViewInit() {
    this.setElementPositionInDom();
  }
/**
 * @param {?} isDisabled
 * @return {?}
 */
setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
/**
 * @return {?}
 */
setElementPositionInDom() {
    this.calendarWrapper = /** @type {?} */(( <HTMLElement> this.calendarContainer.nativeElement));
    this.setInputElementContainer();
    this.popupElem = this.elemRef.nativeElement.querySelector('.dp-popup');
    this.handleInnerElementClick(this.popupElem);

    const {appendTo} = this.componentConfig;
    if (appendTo) {
      if (typeof appendTo === 'string') {
        this.appendToElement = /** @type {?} */(( <HTMLElement>document.querySelector( /** @type {?} */((<string>appendTo)))));
      } else {
        this.appendToElement = /** @type {?} */(( <HTMLElement>appendTo));
      }
    } else {
      this.appendToElement = this.elemRef.nativeElement;
    }

    this.appendToElement.appendChild(this.calendarWrapper);
  }
/**
 * @return {?}
 */
setInputElementContainer() {
    this.inputElementContainer = this.utilsService.getNativeElement(this.componentConfig.inputElementContainer)
      || this.elemRef.nativeElement.querySelector('.dp-input-container')
      || document.body;
  }
/**
 * @param {?} element
 * @return {?}
 */
handleInnerElementClick(element: HTMLElement) {
    this.handleInnerElementClickUnlisteners.push(
      this.renderer.listen(element, 'click', () => {
        this.hideStateHelper = true;
      })
    );
  }
/**
 * @return {?}
 */
init() {
    this.componentConfig = this.dayPickerService.getConfig(this.config, this.mode);
    this.currentDateView = this.displayDate
      ? this.utilsService.convertToMoment(this.displayDate, this.componentConfig.format, this.componentConfig.locale).clone()
      : this.utilsService
        .getDefaultDisplayDate(
          this.currentDateView,
          this.selected,
          this.componentConfig.allowMultiSelect,
          this.componentConfig.min,
          this.componentConfig.locale
        );
    this.inputValueType = this.utilsService.getInputType(this.inputValue, this.componentConfig.allowMultiSelect);
    this.dayCalendarConfig = this.dayPickerService.getDayConfigService(this.componentConfig);
    this.dayTimeCalendarConfig = this.dayPickerService.getDayTimeConfigService(this.componentConfig);
    this.timeSelectConfig = this.dayPickerService.getTimeConfigService(this.componentConfig);
  }
/**
 * @return {?}
 */
inputFocused() {
    if (!this.openOnFocus) {
      return;
    }

    this.isFocusedTrigger = true;
    setTimeout(() => {
      this.hideStateHelper = false;

      if (!this.areCalendarsShown) {
        this.showCalendars();
      }

      this.isFocusedTrigger = false;
    }, this.componentConfig.onOpenDelay);
  }
/**
 * @return {?}
 */
showCalendars() {
    this.hideStateHelper = true;
    this.areCalendarsShown = true;

    if (this.timeSelectRef) {
      this.timeSelectRef.api.triggerChange();
    }

    this.open.emit();
    this.cd.markForCheck();
  }
/**
 * @return {?}
 */
hideCalendar() {
    this.areCalendarsShown = false;

    if (this.dayCalendarRef) {
      this.dayCalendarRef.api.toggleCalendarMode(ECalendarMode.Day);
    }

    this.close.emit();
    this.cd.markForCheck();
  }
/**
 * @param {?} value
 * @return {?}
 */
onViewDateChange(value: CalendarValue) {
    let /** @type {?} */ strVal = value ? this.utilsService.convertToString(value, this.componentConfig.format, this.componentConfig.locale) : '';
    if (this.dayPickerService.isValidInputDateValue(strVal, this.componentConfig)) {
      if (strVal && this.componentConfig.locale === 'fa') {
        // convert jalali to gregorian
        strVal = moment.from(strVal, 'fa', this.componentConfig.format).format(this.componentConfig.format);
      }
      this.selected = this.dayPickerService.convertInputValueToMomentArray(strVal, this.componentConfig);
      this.currentDateView = this.selected.length
        ? this.utilsService.getDefaultDisplayDate(
          null,
          this.selected,
          this.componentConfig.allowMultiSelect,
          this.componentConfig.min,
          this.componentConfig.locale
        )
        : this.currentDateView;
    } else {
      this._selected = this.utilsService
        .getValidMomentArray(strVal, this.componentConfig.format, this.componentConfig.locale);
      this.onChangeCallback(this.processOnChangeCallback(strVal), true);
    }
  }
/**
 * @param {?} date
 * @param {?} granularity
 * @param {?=} ignoreClose
 * @return {?}
 */
dateSelected(date: IDate, granularity: unitOfTime.Base, ignoreClose?: boolean) {
    this.selected = this.utilsService
      .updateSelected(this.componentConfig.allowMultiSelect, this.selected, date, granularity);
    if (!ignoreClose) {
      this.onDateClick();
    }
  }
/**
 * @return {?}
 */
onDateClick() {
    if (this.componentConfig.closeOnSelect) {
      setTimeout(this.hideCalendar.bind(this), this.componentConfig.closeOnSelectDelay);
    }
  }
/**
 * @param {?} event
 * @return {?}
 */
onKeyPress(event: KeyboardEvent) {
    switch (event.keyCode) {
      case (9):
      case (27):
        this.hideCalendar();
        break;
    }
  }
/**
 * @param {?} date
 * @return {?}
 */
moveCalendarTo(date: SingleCalendarValue) {
    const /** @type {?} */ momentDate = this.utilsService.convertToMoment(date, this.componentConfig.format, this.componentConfig.locale);
    this.currentDateView = momentDate;
  }
/**
 * @param {?} change
 * @return {?}
 */
onLeftNavClick(change: INavEvent) {
    this.onLeftNav.emit(change);
  }
/**
 * @param {?} change
 * @return {?}
 */
onRightNavClick(change: INavEvent) {
    this.onRightNav.emit(change);
  }
/**
 * @return {?}
 */
startGlobalListeners() {
    this.globalListnersUnlisteners.push(
      this.renderer.listen(document, 'keydown', (e: KeyboardEvent) => {
        this.onKeyPress(e);
      }),
      this.renderer.listen(document, 'scroll', () => {
        this.onScroll();
      }),
      this.renderer.listen(document, 'click', () => {
        this.onBodyClick();
      })
    );
  }
/**
 * @param {?} locale
 * @return {?}
 */
changeLocale (locale) {
    this.dayCalendarConfig = {...this.dayCalendarConfig, ...{locale}};
    this.dayTimeCalendarConfig = {...this.dayTimeCalendarConfig, ...{locale}};
  }
/**
 * @return {?}
 */
stopGlobalListeners() {
    this.globalListnersUnlisteners.forEach((ul) => ul());
    this.globalListnersUnlisteners = [];
  }
/**
 * @return {?}
 */
ngOnDestroy() {
    this.handleInnerElementClickUnlisteners.forEach(ul => ul());

    if (this.appendToElement) {
      this.appendToElement.removeChild(this.calendarWrapper);
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'dp-date-picker',
  template: `
    <div [ngClass]="{'dp-open': areCalendarsShown}">
      <div class="dp-input-container"
           [hidden]="componentConfig.hideInputContainer"
           [attr.data-hidden]="componentConfig.hideInputContainer">
        <input type="text"
               class="dp-picker-input"
               [placeholder]="placeholder"
               [ngModel]="inputElementValue"
               (ngModelChange)="onViewDateChange($event)"
               (focus)="inputFocused()"
               [readonly]="componentConfig.disableKeypress"
               [disabled]="disabled"/>
      </div>
      <div #container>
        <div class="dp-popup {{theme}}"
             [ngSwitch]="mode"
             [hidden]="!_areCalendarsShown"
             [attr.data-hidden]="!_areCalendarsShown">
          <dp-day-calendar #dayCalendar
                           *ngSwitchCase="'day'"
                           [config]="dayCalendarConfig"
                           [ngModel]="_selected"
                           [displayDate]="displayDate"
                           [theme]="theme"
                           (onSelect)="dateSelected($event, 'day')"
                           (onGoToCurrent)="onGoToCurrent.emit()"
                           (onLeftNav)="onLeftNavClick($event)"
                           (onRightNav)="onRightNavClick($event)">
          </dp-day-calendar>

          <dp-month-calendar #monthCalendar
                             *ngSwitchCase="'month'"
                             [config]="dayCalendarConfig"
                             [ngModel]="_selected"
                             [displayDate]="displayDate"
                             [theme]="theme"
                             (onSelect)="dateSelected($event, 'month')"
                             (onGoToCurrent)="onGoToCurrent.emit()"
                             (onLeftNav)="onLeftNavClick($event)"
                             (onRightNav)="onRightNavClick($event)">
          </dp-month-calendar>

          <dp-time-select #timeSelect
                          *ngSwitchCase="'time'"
                          [config]="timeSelectConfig"
                          [ngModel]="_selected && _selected[0]"
                          (onChange)="dateSelected($event, 'second', true)"
                          [theme]="theme">
          </dp-time-select>

          <dp-day-time-calendar #daytimeCalendar
                                *ngSwitchCase="'daytime'"
                                [config]="dayTimeCalendarConfig"
                                [displayDate]="displayDate"
                                [ngModel]="_selected && _selected[0]"
                                [theme]="theme"
                                (onChange)="dateSelected($event, 'second', true)"
                                (onGoToCurrent)="onGoToCurrent.emit()"
                                (onLeftNav)="onLeftNavClick($event)"
                                (onRightNav)="onRightNavClick($event)">
          </dp-day-time-calendar>
        </div>
      </div>
    </div>
  `,
  styles: [`
    dp-date-picker {
      display: inline-block;
    }
    dp-date-picker.dp-material .dp-picker-input {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 30px;
      width: 252px;
      font-size: 13px;
      outline: none;
    }
    dp-date-picker .dp-input-container {
      position: relative;
    }
    dp-date-picker .dp-selected {
      background: rgba(16, 108, 200, 0.5);
      color: #FFFFFF;
    }
    .dp-popup {
      position: relative;
      background: #FFFFFF;
      -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      z-index: 9999;
      white-space: nowrap;
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DatePickerService,
    DayTimeCalendarService,
    DayCalendarService,
    TimeSelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: DatePickerService, },
{type: DomHelper, },
{type: ElementRef, },
{type: Renderer2, },
{type: UtilsService, },
{type: ChangeDetectorRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'config': [{ type: Input },],
'mode': [{ type: Input },],
'placeholder': [{ type: Input },],
'disabled': [{ type: Input },],
'displayDate': [{ type: Input },],
'theme': [{ type: HostBinding, args: ['class', ] },{ type: Input },],
'minDate': [{ type: Input },],
'maxDate': [{ type: Input },],
'minTime': [{ type: Input },],
'maxTime': [{ type: Input },],
'open': [{ type: Output },],
'close': [{ type: Output },],
'onChange': [{ type: Output },],
'onGoToCurrent': [{ type: Output },],
'onLeftNav': [{ type: Output },],
'onRightNav': [{ type: Output },],
'calendarContainer': [{ type: ViewChild, args: ['container', ] },],
'dayCalendarRef': [{ type: ViewChild, args: ['dayCalendar', ] },],
'monthCalendarRef': [{ type: ViewChild, args: ['monthCalendar', ] },],
'dayTimeCalendarRef': [{ type: ViewChild, args: ['daytimeCalendar', ] },],
'timeSelectRef': [{ type: ViewChild, args: ['timeSelect', ] },],
'onClick': [{ type: HostListener, args: ['click', ] },],
'onScroll': [{ type: HostListener, args: ['window:resize', ] },],
};
}

function DatePickerComponent_tsickle_Closure_declarations() {
/** @type {?} */
DatePickerComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DatePickerComponent.ctorParameters;
/** @type {?} */
DatePickerComponent.propDecorators;
/** @type {?} */
DatePickerComponent.prototype.isInitialized;
/** @type {?} */
DatePickerComponent.prototype.config;
/** @type {?} */
DatePickerComponent.prototype.mode;
/** @type {?} */
DatePickerComponent.prototype.placeholder;
/** @type {?} */
DatePickerComponent.prototype.disabled;
/** @type {?} */
DatePickerComponent.prototype.displayDate;
/** @type {?} */
DatePickerComponent.prototype.theme;
/** @type {?} */
DatePickerComponent.prototype.minDate;
/** @type {?} */
DatePickerComponent.prototype.maxDate;
/** @type {?} */
DatePickerComponent.prototype.minTime;
/** @type {?} */
DatePickerComponent.prototype.maxTime;
/** @type {?} */
DatePickerComponent.prototype.open;
/** @type {?} */
DatePickerComponent.prototype.close;
/** @type {?} */
DatePickerComponent.prototype.onChange;
/** @type {?} */
DatePickerComponent.prototype.onGoToCurrent;
/** @type {?} */
DatePickerComponent.prototype.onLeftNav;
/** @type {?} */
DatePickerComponent.prototype.onRightNav;
/** @type {?} */
DatePickerComponent.prototype.calendarContainer;
/** @type {?} */
DatePickerComponent.prototype.dayCalendarRef;
/** @type {?} */
DatePickerComponent.prototype.monthCalendarRef;
/** @type {?} */
DatePickerComponent.prototype.dayTimeCalendarRef;
/** @type {?} */
DatePickerComponent.prototype.timeSelectRef;
/** @type {?} */
DatePickerComponent.prototype.componentConfig;
/** @type {?} */
DatePickerComponent.prototype.dayCalendarConfig;
/** @type {?} */
DatePickerComponent.prototype.dayTimeCalendarConfig;
/** @type {?} */
DatePickerComponent.prototype.timeSelectConfig;
/** @type {?} */
DatePickerComponent.prototype._areCalendarsShown;
/** @type {?} */
DatePickerComponent.prototype.hideStateHelper;
/** @type {?} */
DatePickerComponent.prototype._selected;
/** @type {?} */
DatePickerComponent.prototype.inputValue;
/** @type {?} */
DatePickerComponent.prototype.inputValueType;
/** @type {?} */
DatePickerComponent.prototype.isFocusedTrigger;
/** @type {?} */
DatePickerComponent.prototype._currentDateView;
/** @type {?} */
DatePickerComponent.prototype.inputElementValue;
/** @type {?} */
DatePickerComponent.prototype.calendarWrapper;
/** @type {?} */
DatePickerComponent.prototype.appendToElement;
/** @type {?} */
DatePickerComponent.prototype.inputElementContainer;
/** @type {?} */
DatePickerComponent.prototype.popupElem;
/** @type {?} */
DatePickerComponent.prototype.handleInnerElementClickUnlisteners;
/** @type {?} */
DatePickerComponent.prototype.globalListnersUnlisteners;
/** @type {?} */
DatePickerComponent.prototype.validateFn;
/** @type {?} */
DatePickerComponent.prototype.api;
/** @type {?} */
DatePickerComponent.prototype.dayPickerService;
/** @type {?} */
DatePickerComponent.prototype.domHelper;
/** @type {?} */
DatePickerComponent.prototype.elemRef;
/** @type {?} */
DatePickerComponent.prototype.renderer;
/** @type {?} */
DatePickerComponent.prototype.utilsService;
/** @type {?} */
DatePickerComponent.prototype.cd;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
