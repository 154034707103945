
export type ECalendarMode = number;
export let ECalendarMode: any = {};
ECalendarMode.Day = 0;
ECalendarMode.DayTime = 1;
ECalendarMode.Month = 2;
ECalendarMode.Time = 3;
ECalendarMode[ECalendarMode.Day] = "Day";
ECalendarMode[ECalendarMode.DayTime] = "DayTime";
ECalendarMode[ECalendarMode.Month] = "Month";
ECalendarMode[ECalendarMode.Time] = "Time";
