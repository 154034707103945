
export type ECalendarValue = number;
export let ECalendarValue: any = {};
ECalendarValue.Moment = 1;
ECalendarValue.MomentArr = 2;
ECalendarValue.String = 3;
ECalendarValue.StringArr = 4;
ECalendarValue[ECalendarValue.Moment] = "Moment";
ECalendarValue[ECalendarValue.MomentArr] = "MomentArr";
ECalendarValue[ECalendarValue.String] = "String";
ECalendarValue[ECalendarValue.StringArr] = "StringArr";
