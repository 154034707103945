import {EventEmitter, Injectable} from '@angular/core';
import {IDatePickerConfig, IDatePickerConfigInternal} from './date-picker-config.model';
import * as momentNs from 'jalali-moment';
import {Moment} from 'jalali-moment';
import {UtilsService} from '../common/services/utils/utils.service';
import {IDayCalendarConfig} from '../day-calendar/day-calendar-config.model';
import {TimeSelectService} from '../time-select/time-select.service';
import {DayTimeCalendarService} from '../day-time-calendar/day-time-calendar.service';
import {ITimeSelectConfig} from '../time-select/time-select-config.model';
import {CalendarMode} from '../common/types/calendar-mode';
import {CalendarValue} from '../common/types/calendar-value';
const /** @type {?} */ moment = momentNs;
export class DatePickerService {
  readonly onPickerClosed: EventEmitter<null> = new EventEmitter();
private defaultConfig: IDatePickerConfigInternal = {
    closeOnSelect: true,
    closeOnSelectDelay: 100,
    format: 'YYYY-MM-D',
    openOnFocus: true,
    openOnClick: true,
    onOpenDelay: 0,
    disableKeypress: false,
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    showGoToCurrent: true,
    locale: 'fa',
    hideOnOutsideClick: true
  };
private gregorianExtensionConfig: IDatePickerConfig = {
    format: 'DD-MM-YYYY',
    locale: 'en'
  };
/**
 * @param {?} utilsService
 * @param {?} timeSelectService
 * @param {?} daytimeCalendarService
 */
constructor(private utilsService: UtilsService,
private timeSelectService: TimeSelectService,
private daytimeCalendarService: DayTimeCalendarService) {
  }
/**
 * @param {?} config
 * @param {?=} mode
 * @return {?}
 */
getConfig(config: IDatePickerConfig, mode: CalendarMode = 'daytime'): IDatePickerConfigInternal {
    const /** @type {?} */ _config = /** @type {?} */(( <IDatePickerConfigInternal>{
      ...this.defaultConfig,
      ...((config && config.locale && config.locale !== 'fa') ? this.gregorianExtensionConfig : {}),
      format: this.getDefaultFormatByMode(mode, config),
      ...this.utilsService.clearUndefined(config)
    }));

    this.utilsService.convertPropsToMoment(_config, _config.format, ['min', 'max'], _config.locale);

    if (config && config.allowMultiSelect && config.closeOnSelect === undefined) {
      _config.closeOnSelect = false;
    }

    // moment.locale(_config.locale);

    return _config;
  }
/**
 * @param {?} pickerConfig
 * @return {?}
 */
getDayConfigService(pickerConfig: IDatePickerConfig): IDayCalendarConfig {
    return {
      min: pickerConfig.min,
      max: pickerConfig.max,
      isDayDisabledCallback: pickerConfig.isDayDisabledCallback,
      weekDayFormat: pickerConfig.weekDayFormat,
      showNearMonthDays: pickerConfig.showNearMonthDays,
      showWeekNumbers: pickerConfig.showWeekNumbers,
      firstDayOfWeek: pickerConfig.firstDayOfWeek,
      format: pickerConfig.format,
      allowMultiSelect: pickerConfig.allowMultiSelect,
      monthFormat: pickerConfig.monthFormat,
      monthFormatter: pickerConfig.monthFormatter,
      enableMonthSelector: pickerConfig.enableMonthSelector,
      yearFormat: pickerConfig.yearFormat,
      yearFormatter: pickerConfig.yearFormatter,
      dayBtnFormat: pickerConfig.dayBtnFormat,
      dayBtnFormatter: pickerConfig.dayBtnFormatter,
      dayBtnCssClassCallback: pickerConfig.dayBtnCssClassCallback,
      monthBtnFormat: pickerConfig.monthBtnFormat,
      monthBtnFormatter: pickerConfig.monthBtnFormatter,
      monthBtnCssClassCallback: pickerConfig.monthBtnCssClassCallback,
      multipleYearsNavigateBy: pickerConfig.multipleYearsNavigateBy,
      showMultipleYearsNavigation: pickerConfig.showMultipleYearsNavigation,
      locale: pickerConfig.locale,
      returnedValueType: pickerConfig.returnedValueType,
      showGoToCurrent: pickerConfig.showGoToCurrent,
      unSelectOnClick: pickerConfig.unSelectOnClick
    };
  }
/**
 * @param {?} pickerConfig
 * @return {?}
 */
getDayTimeConfigService(pickerConfig: IDatePickerConfig): ITimeSelectConfig {
    return this.daytimeCalendarService.getConfig(pickerConfig);
  }
/**
 * @param {?} pickerConfig
 * @return {?}
 */
getTimeConfigService(pickerConfig: IDatePickerConfig): ITimeSelectConfig {
    return this.timeSelectService.getConfig(pickerConfig);
  }
/**
 * @return {?}
 */
pickerClosed() {
    this.onPickerClosed.emit();
  }
/**
 * @param {?} value
 * @param {?} config
 * @return {?}
 */
isValidInputDateValue(value: string, config: IDatePickerConfig): boolean {
    value = value ? value : '';
    const /** @type {?} */ datesStrArr: string[] = this.utilsService.datesStringToStringArray(value);

    return datesStrArr.every(date => this.utilsService.isDateValid(date, config.format, config.locale));
  }
/**
 * @param {?} value
 * @param {?} config
 * @return {?}
 */
convertInputValueToMomentArray(value: string, config: IDatePickerConfig): Moment[] {
    value = value ? value : '';
    const /** @type {?} */ datesStrArr: string[] = this.utilsService.datesStringToStringArray(value);

    return this.utilsService.convertToMomentArray(datesStrArr, config.format, config.allowMultiSelect, config.locale);
  }
/**
 * @param {?} mode
 * @param {?} config
 * @return {?}
 */
private getDefaultFormatByMode(mode: CalendarMode, config: IDatePickerConfig): string {
    let /** @type {?} */ dateFormat = 'YYYY-MM-DD';
    let /** @type {?} */ monthFormat = 'MMMM YY';
    const /** @type {?} */ timeFormat = 'HH:mm:ss';
    if (config && config.locale && config.locale !== 'fa') {
      dateFormat = 'DD-MM-YYYY';
      monthFormat = 'MMM, YYYY';
    }
    switch (mode) {
      case 'day':
        return dateFormat;
      case 'daytime':
        return dateFormat + ' ' + timeFormat;
      case 'time':
        return timeFormat;
      case 'month':
        return monthFormat;
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: UtilsService, },
{type: TimeSelectService, },
{type: DayTimeCalendarService, },
];
}

function DatePickerService_tsickle_Closure_declarations() {
/** @type {?} */
DatePickerService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DatePickerService.ctorParameters;
/** @type {?} */
DatePickerService.prototype.onPickerClosed;
/** @type {?} */
DatePickerService.prototype.defaultConfig;
/** @type {?} */
DatePickerService.prototype.gregorianExtensionConfig;
/** @type {?} */
DatePickerService.prototype.utilsService;
/** @type {?} */
DatePickerService.prototype.timeSelectService;
/** @type {?} */
DatePickerService.prototype.daytimeCalendarService;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
