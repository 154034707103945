import {ECalendarValue} from '../common/types/calendar-value-enum';
import {SingleCalendarValue} from '../common/types/single-calendar-value';
import {ECalendarMode} from '../common/types/calendar-mode-enum';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {DayCalendarService} from './day-calendar.service';
import * as momentNs from 'jalali-moment';
import {Moment, MomentInput, unitOfTime} from 'jalali-moment';
import {IDayCalendarConfig, IDayCalendarConfigInternal} from './day-calendar-config.model';
import {IDay} from './day.model';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {CalendarValue} from '../common/types/calendar-value';
import {UtilsService} from '../common/services/utils/utils.service';
import {IMonthCalendarConfig} from '../month-calendar/month-calendar-config';
import {IMonth} from '../month-calendar/month.model';
import {DateValidator} from '../common/types/validator.type';
import {INavEvent} from '../common/models/navigation-event.model';
const /** @type {?} */ moment = momentNs;
export class DayCalendarComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

   config: IDayCalendarConfig;
   displayDate: SingleCalendarValue;
   minDate: Moment;
   maxDate: Moment;
    theme: string;

   onSelect: EventEmitter<IDay> = new EventEmitter();
   onMonthSelect: EventEmitter<IMonth> = new EventEmitter();
   onNavHeaderBtnClick: EventEmitter<ECalendarMode> = new EventEmitter();
   onGoToCurrent: EventEmitter<void> = new EventEmitter();
   onLeftNav: EventEmitter<INavEvent> = new EventEmitter();
   onRightNav: EventEmitter<INavEvent> = new EventEmitter();

  CalendarMode = ECalendarMode;
  isInited: boolean = false;
  componentConfig: IDayCalendarConfigInternal;
  _selected: Moment[];
  weeks: IDay[][];
  weekdays: Moment[];
  _currentDateView: Moment;
  inputValue: CalendarValue;
  inputValueType: ECalendarValue;
  validateFn: DateValidator;
  currentCalendarMode: ECalendarMode = ECalendarMode.Day;
  monthCalendarConfig: IMonthCalendarConfig;
  _shouldShowCurrent: boolean = true;
  navLabel: string;
  showLeftNav: boolean;
  showRightNav: boolean;

  api = {
    moveCalendarsBy: this.moveCalendarsBy.bind(this),
    moveCalendarTo: this.moveCalendarTo.bind(this),
    toggleCalendarMode: this.toggleCalendarMode.bind(this)
  };
/**
 * @param {?} selected
 * @return {?}
 */
set selected(selected: Moment[]) {
    this._selected = selected;
    this.onChangeCallback(this.processOnChangeCallback(selected));
  }
/**
 * @return {?}
 */
get selected(): Moment[] {
    return this._selected;
  }
/**
 * @param {?} current
 * @return {?}
 */
set currentDateView(current: Moment) {
    this._currentDateView = current.clone();
    this.weeks = this.dayCalendarService
      .generateMonthArray(this.componentConfig, this._currentDateView, this.selected);
    this.navLabel = this.dayCalendarService.getHeaderLabel(this.componentConfig, this._currentDateView);
    this.showLeftNav = this.dayCalendarService.shouldShowLeft(this.componentConfig.min, this.currentDateView);
    this.showRightNav = this.dayCalendarService.shouldShowRight(this.componentConfig.max, this.currentDateView);
  }
/**
 * @return {?}
 */
get currentDateView(): Moment {
    return this._currentDateView;
  }
/**
 * @param {?} dayCalendarService
 * @param {?} utilsService
 * @param {?} cd
 */
constructor(public readonly dayCalendarService: DayCalendarService,
public readonly utilsService: UtilsService,
public readonly cd: ChangeDetectorRef) {
  }
/**
 * @return {?}
 */
ngOnInit() {
    this.isInited = true;
    this.init();
    this.initValidators();
  }
/**
 * @return {?}
 */
init() {
    this.componentConfig = this.dayCalendarService.getConfig(this.config);
    this.selected = this.selected || [];
    this.currentDateView = this.displayDate
      ? this.utilsService.convertToMoment(this.displayDate, this.componentConfig.format, this.componentConfig.locale).clone()
      : this.utilsService
        .getDefaultDisplayDate(
          this.currentDateView,
          this.selected,
          this.componentConfig.allowMultiSelect,
          this.componentConfig.min,
          this.componentConfig.locale
        );
    this.weekdays = this.dayCalendarService
      .generateWeekdays(this.componentConfig.firstDayOfWeek, this.componentConfig.locale);
    this.inputValueType = this.utilsService.getInputType(this.inputValue, this.componentConfig.allowMultiSelect);
    this.monthCalendarConfig = this.dayCalendarService.getMonthCalendarConfig(this.componentConfig);
    this._shouldShowCurrent = this.shouldShowCurrent();
  }
/**
 * @return {?}
 */
isFarsi() {
    return this.componentConfig.locale === 'fa';
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {
    if (this.isInited) {
      const {minDate, maxDate, config} = changes;

      this.handleConfigChange(config);
      this.init();

      if (minDate || maxDate) {
        this.initValidators();
      }
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
writeValue(value: CalendarValue): void {
    if (value === this.inputValue
      || (this.inputValue
       && (moment.isMoment(this.inputValue)) && ( /** @type {?} */((this.inputValue as Moment))).isSame( /** @type {?} */((<MomentInput>value))))
    ) {
      return;
    }

    this.inputValue = value;

    if (value) {
      this.selected = this.utilsService
        .convertToMomentArray(value, this.componentConfig.format, this.componentConfig.allowMultiSelect, this.componentConfig.locale);
      this.inputValueType = this.utilsService
        .getInputType(this.inputValue, this.componentConfig.allowMultiSelect);
    } else {
      this.selected = [];
    }

    this.weeks = this.dayCalendarService
      .generateMonthArray(this.componentConfig, this.currentDateView, this.selected);

    this.cd.markForCheck();
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
/**
 * @param {?} _
 * @return {?}
 */
onChangeCallback(_: any) {
  };
/**
 * @param {?} fn
 * @return {?}
 */
registerOnTouched(fn: any): void {
  }
/**
 * @param {?} formControl
 * @return {?}
 */
validate(formControl: FormControl): ValidationErrors | any {
    if (this.minDate || this.maxDate) {
      return this.validateFn(formControl.value);
    } else {
      return () => null;
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
processOnChangeCallback(value: Moment[]): CalendarValue {
    return this.utilsService.convertFromMomentArray(
      this.componentConfig.format,
      value,
      this.componentConfig.returnedValueType || this.inputValueType,
      this.componentConfig.locale
    );
  }
/**
 * @return {?}
 */
initValidators() {
    this.validateFn = this.utilsService.createValidator(
      {minDate: this.minDate, maxDate: this.maxDate},
      this.componentConfig.format,
      'day',
      this.componentConfig.locale
    );

    this.onChangeCallback(this.processOnChangeCallback(this.selected));
  }
/**
 * @param {?} day
 * @return {?}
 */
dayClicked(day: IDay) {
    if (day.selected && !this.componentConfig.unSelectOnClick) {
      return;
    }

    this.selected = this.utilsService
      .updateSelected(this.componentConfig.allowMultiSelect, this.selected, day);
    this.weeks = this.dayCalendarService
      .generateMonthArray(this.componentConfig, this.currentDateView, this.selected);
    this.onSelect.emit(day);
  }
/**
 * @param {?} day
 * @return {?}
 */
getDayBtnText(day: IDay): string {
    return this.dayCalendarService.getDayBtnText(this.componentConfig, day.date);
  }
/**
 * @param {?} day
 * @return {?}
 */
getDayBtnCssClass(day: IDay): {[klass: string]: boolean} {
    const /** @type {?} */ cssClasses: {[klass: string]: boolean} = {
      'dp-selected': day.selected,
      'dp-current-month': day.currentMonth,
      'dp-prev-month': day.prevMonth,
      'dp-next-month': day.nextMonth,
      'dp-current-day': day.currentDay
    };
    const /** @type {?} */ customCssClass: string = this.dayCalendarService.getDayBtnCssClass(this.componentConfig, day.date);
    if (customCssClass) {
      cssClasses[customCssClass] = true;
    }

    return cssClasses;
  }
/**
 * @return {?}
 */
onLeftNavClick() {
    const /** @type {?} */ from = this.currentDateView.clone();
    this.moveCalendarsBy(this.currentDateView, -1, 'month');
    const /** @type {?} */ to = this.currentDateView.clone();
    this.onLeftNav.emit({from, to});
  }
/**
 * @return {?}
 */
onRightNavClick() {
    const /** @type {?} */ from = this.currentDateView.clone();
    this.moveCalendarsBy(this.currentDateView, 1, 'month');
    const /** @type {?} */ to = this.currentDateView.clone();
    this.onRightNav.emit({from, to});
  }
/**
 * @param {?} change
 * @return {?}
 */
onMonthCalendarLeftClick(change: INavEvent) {
    this.onLeftNav.emit(change);
  }
/**
 * @param {?} change
 * @return {?}
 */
onMonthCalendarRightClick(change: INavEvent) {
    this.onRightNav.emit(change);
  }
/**
 * @param {?} change
 * @return {?}
 */
onMonthCalendarSecondaryLeftClick(change: INavEvent) {
    this.onRightNav.emit(change);
  }
/**
 * @param {?} change
 * @return {?}
 */
onMonthCalendarSecondaryRightClick(change: INavEvent) {
    this.onLeftNav.emit(change);
  }
/**
 * @param {?} weekday
 * @return {?}
 */
getWeekdayName(weekday: Moment): string {
    if (this.componentConfig.weekDayFormatter) {
      return this.componentConfig.weekDayFormatter(weekday.day());
    }

    return weekday.format(this.componentConfig.weekDayFormat);
  }
/**
 * @param {?} mode
 * @return {?}
 */
toggleCalendarMode(mode: ECalendarMode) {
    if (this.currentCalendarMode !== mode) {
      this.currentCalendarMode = mode;
      this.onNavHeaderBtnClick.emit(mode);
    }

    this.cd.markForCheck();
  }
/**
 * @param {?} month
 * @return {?}
 */
monthSelected(month: IMonth) {
    this.currentDateView = month.date.clone();
    this.currentCalendarMode = ECalendarMode.Day;
    this.onMonthSelect.emit(month);
  }
/**
 * @param {?} current
 * @param {?} amount
 * @param {?=} granularity
 * @return {?}
 */
moveCalendarsBy(current: Moment, amount: number, granularity: unitOfTime.Base = 'month') {
    this.currentDateView = current.clone().add(amount, granularity);
    this.cd.markForCheck();
  }
/**
 * @param {?} to
 * @return {?}
 */
moveCalendarTo(to: SingleCalendarValue) {
    if (to) {
      this.currentDateView = this.utilsService.convertToMoment(to, this.componentConfig.format, this.componentConfig.locale);
    }

    this.cd.markForCheck();
  }
/**
 * @return {?}
 */
shouldShowCurrent(): boolean {
    return this.utilsService.shouldShowCurrent(
      this.componentConfig.showGoToCurrent,
      'day',
      this.componentConfig.min,
      this.componentConfig.max
    );
  }
/**
 * @return {?}
 */
goToCurrent() {
    this.currentDateView = moment().locale(this.componentConfig.locale);
    this.onGoToCurrent.emit();
  }
/**
 * @param {?} config
 * @return {?}
 */
handleConfigChange(config: SimpleChange) {
    if (config) {
      const /** @type {?} */ prevConf: IDayCalendarConfigInternal = this.dayCalendarService.getConfig(config.previousValue);
      const /** @type {?} */ currentConf: IDayCalendarConfigInternal = this.dayCalendarService.getConfig(config.currentValue);

      if (this.utilsService.shouldResetCurrentView(prevConf, currentConf)) {
        this._currentDateView = null;
      }
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'dp-day-calendar',
  template: `
    <div class="dp-day-calendar-container" *ngIf="currentCalendarMode ===  CalendarMode.Day">
      <dp-calendar-nav
          [label]="navLabel"
          [showLeftNav]="showLeftNav"
          [showRightNav]="showRightNav"
          [isLabelClickable]="componentConfig.enableMonthSelector"
          [showGoToCurrent]="_shouldShowCurrent"
          [theme]="theme"
          (onLeftNav)="onLeftNavClick()"
          (onRightNav)="onRightNavClick()"
          (onLabelClick)="toggleCalendarMode(CalendarMode.Month)"
          (onGoToCurrent)="goToCurrent()">
      </dp-calendar-nav>

      <div class="dp-calendar-wrapper"
           [ngClass]="{'dp-hide-near-month': !componentConfig.showNearMonthDays,'rtl':isFarsi()}">
        <div class="dp-weekdays">
          <span class="dp-calendar-weekday"
                *ngFor="let weekday of weekdays"
                [innerText]="getWeekdayName(weekday)">
          </span>
        </div>
        <div class="dp-calendar-week" *ngFor="let week of weeks">
          <span class="dp-week-number"
                *ngIf="componentConfig.showWeekNumbers"
                [innerText]="week[0].date.isoWeek()">
          </span>
          <button type="button"
                  class="dp-calendar-day"
                  *ngFor="let day of week"
                  [attr.data-date]="day.date.format(componentConfig.format)"
                  (click)="dayClicked(day)"
                  [disabled]="day.disabled"
                  [ngClass]="getDayBtnCssClass(day)"
                  [innerText]="getDayBtnText(day)">
          </button>
        </div>
      </div>
    </div>

    <dp-month-calendar
        *ngIf="currentCalendarMode ===  CalendarMode.Month"
        [config]="monthCalendarConfig"
        [displayDate]="_currentDateView"
        [theme]="theme"
        (onSelect)="monthSelected($event)"
        (onNavHeaderBtnClick)="toggleCalendarMode(CalendarMode.Day)"
        (onLeftNav)="onMonthCalendarLeftClick($event)"
        (onRightNav)="onMonthCalendarRightClick($event)"
        (onLeftSecondaryNav)="onMonthCalendarSecondaryLeftClick($event)"
        (onRightSecondaryNav)="onMonthCalendarSecondaryRightClick($event)">
    </dp-month-calendar>
  `,
  styles: [`
    dp-day-calendar {
      display: inline-block;
    }
    dp-day-calendar .dp-day-calendar-container {
      background: #FFFFFF;
    }
    dp-day-calendar .dp-calendar-wrapper {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }
    dp-day-calendar .dp-calendar-wrapper .dp-calendar-weekday:first-child {
      border-left: none;
    }
    dp-day-calendar .dp-weekdays {
      font-size: 15px;
      margin-bottom: 5px;
    }
    dp-day-calendar .dp-calendar-weekday {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      display: inline-block;
      width: 30px;
      text-align: center;
      border-left: 1px solid #000000;
      border-bottom: 1px solid #000000;
    }
    dp-day-calendar .dp-calendar-day {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    dp-day-calendar .dp-selected {
      background: rgba(16, 108, 200, 0.5);
      color: #FFFFFF;
    }
    dp-day-calendar .dp-prev-month,
    dp-day-calendar .dp-next-month {
      opacity: 0.5;
    }
    dp-day-calendar .dp-hide-near-month .dp-prev-month,
    dp-day-calendar .dp-hide-near-month .dp-next-month {
      visibility: hidden;
    }
    dp-day-calendar .dp-week-number {
      position: absolute;
      font-size: 9px;
    }
    dp-day-calendar.dp-material .dp-calendar-weekday {
      height: 25px;
      width: 30px;
      line-height: 25px;
      color: rgba(16, 108, 200, 0.5);
      border: none;
      font-size: 0.75rem;
      opacity: 0.6;
    }
    dp-day-calendar.dp-material .dp-calendar-weekday:last-child {
      color: red;
    }
    dp-day-calendar.dp-material .dp-calendar-wrapper {
      padding: 20px;
    }
    dp-day-calendar.dp-material .dp-calendar-wrapper.rtl {
      direction: rtl;
    }
    dp-day-calendar.dp-material .dp-calendar-month,
    dp-day-calendar.dp-material .dp-calendar-day {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 0%;
      -webkit-transition: border-radius 0.1s ease;
      transition: border-radius 0.1s ease;
      border: none;
      outline: none;
      padding: 0;
    }
    dp-day-calendar.dp-material .dp-calendar-month:hover,
    dp-day-calendar.dp-material .dp-calendar-day:hover {
      background: #E0E0E0;
      border-radius: 50%;
    }
    dp-day-calendar.dp-material .dp-selected {
      border-radius: 50%;
      background: rgba(16, 108, 200, 0.5);
      color: #FFFFFF;
    }
    dp-day-calendar.dp-material .dp-selected:hover {
      background: rgba(16, 108, 200, 0.5);
    }
    dp-day-calendar.dp-material .dp-current-day {
      border-radius: 50%;
      border: 1px solid rgba(16, 108, 200, 0.5);
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DayCalendarService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DayCalendarComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DayCalendarComponent),
      multi: true
    }
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: DayCalendarService, },
{type: UtilsService, },
{type: ChangeDetectorRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'config': [{ type: Input },],
'displayDate': [{ type: Input },],
'minDate': [{ type: Input },],
'maxDate': [{ type: Input },],
'theme': [{ type: HostBinding, args: ['class', ] },{ type: Input },],
'onSelect': [{ type: Output },],
'onMonthSelect': [{ type: Output },],
'onNavHeaderBtnClick': [{ type: Output },],
'onGoToCurrent': [{ type: Output },],
'onLeftNav': [{ type: Output },],
'onRightNav': [{ type: Output },],
};
}

function DayCalendarComponent_tsickle_Closure_declarations() {
/** @type {?} */
DayCalendarComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DayCalendarComponent.ctorParameters;
/** @type {?} */
DayCalendarComponent.propDecorators;
/** @type {?} */
DayCalendarComponent.prototype.config;
/** @type {?} */
DayCalendarComponent.prototype.displayDate;
/** @type {?} */
DayCalendarComponent.prototype.minDate;
/** @type {?} */
DayCalendarComponent.prototype.maxDate;
/** @type {?} */
DayCalendarComponent.prototype.theme;
/** @type {?} */
DayCalendarComponent.prototype.onSelect;
/** @type {?} */
DayCalendarComponent.prototype.onMonthSelect;
/** @type {?} */
DayCalendarComponent.prototype.onNavHeaderBtnClick;
/** @type {?} */
DayCalendarComponent.prototype.onGoToCurrent;
/** @type {?} */
DayCalendarComponent.prototype.onLeftNav;
/** @type {?} */
DayCalendarComponent.prototype.onRightNav;
/** @type {?} */
DayCalendarComponent.prototype.CalendarMode;
/** @type {?} */
DayCalendarComponent.prototype.isInited;
/** @type {?} */
DayCalendarComponent.prototype.componentConfig;
/** @type {?} */
DayCalendarComponent.prototype._selected;
/** @type {?} */
DayCalendarComponent.prototype.weeks;
/** @type {?} */
DayCalendarComponent.prototype.weekdays;
/** @type {?} */
DayCalendarComponent.prototype._currentDateView;
/** @type {?} */
DayCalendarComponent.prototype.inputValue;
/** @type {?} */
DayCalendarComponent.prototype.inputValueType;
/** @type {?} */
DayCalendarComponent.prototype.validateFn;
/** @type {?} */
DayCalendarComponent.prototype.currentCalendarMode;
/** @type {?} */
DayCalendarComponent.prototype.monthCalendarConfig;
/** @type {?} */
DayCalendarComponent.prototype._shouldShowCurrent;
/** @type {?} */
DayCalendarComponent.prototype.navLabel;
/** @type {?} */
DayCalendarComponent.prototype.showLeftNav;
/** @type {?} */
DayCalendarComponent.prototype.showRightNav;
/** @type {?} */
DayCalendarComponent.prototype.api;
/** @type {?} */
DayCalendarComponent.prototype.dayCalendarService;
/** @type {?} */
DayCalendarComponent.prototype.utilsService;
/** @type {?} */
DayCalendarComponent.prototype.cd;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
