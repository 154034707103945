import {ECalendarValue} from '../common/types/calendar-value-enum';
import {SingleCalendarValue} from '../common/types/single-calendar-value';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {TimeSelectService, TimeUnit} from './time-select.service';
import * as momentNs from 'jalali-moment';
import {Moment} from 'jalali-moment';
import {ITimeSelectConfig, ITimeSelectConfigInternal} from './time-select-config.model';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {CalendarValue} from '../common/types/calendar-value';
import {UtilsService} from '../common/services/utils/utils.service';
import {IDate} from '../common/models/date.model';
import {DateValidator} from '../common/types/validator.type';
const /** @type {?} */ moment = momentNs;
export class TimeSelectComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

   config: ITimeSelectConfig;
   displayDate: SingleCalendarValue;
   minDate: SingleCalendarValue;
   maxDate: SingleCalendarValue;
   minTime: SingleCalendarValue;
   maxTime: SingleCalendarValue;
    theme: string;

   onChange: EventEmitter<IDate> = new EventEmitter();

  isInited: boolean = false;
  componentConfig: ITimeSelectConfigInternal;
  _selected: Moment;
  inputValue: CalendarValue;
  inputValueType: ECalendarValue;
  validateFn: DateValidator;

  hours: string;
  minutes: string;
  seconds: string;
  meridiem: string;

  showDecHour: boolean;
  showDecMinute: boolean;
  showDecSecond: boolean;
  showIncHour: boolean;
  showIncMinute: boolean;
  showIncSecond: boolean;
  showToggleMeridiem: boolean;
/**
 * @param {?} selected
 * @return {?}
 */
set selected(selected: Moment) {
    this._selected = selected;
    this.calculateTimeParts(this.selected);

    this.showDecHour = this.timeSelectService.shouldShowDecrease(this.componentConfig, this._selected, 'hour');
    this.showDecMinute = this.timeSelectService.shouldShowDecrease(this.componentConfig, this._selected, 'minute');
    this.showDecSecond = this.timeSelectService.shouldShowDecrease(this.componentConfig, this._selected, 'second');

    this.showIncHour = this.timeSelectService.shouldShowIncrease(this.componentConfig, this._selected, 'hour');
    this.showIncMinute = this.timeSelectService.shouldShowIncrease(this.componentConfig, this._selected, 'minute');
    this.showIncSecond = this.timeSelectService.shouldShowIncrease(this.componentConfig, this._selected, 'second');

    this.showToggleMeridiem = this.timeSelectService.shouldShowToggleMeridiem(this.componentConfig, this._selected);

    this.onChangeCallback(this.processOnChangeCallback(selected));
  }
/**
 * @return {?}
 */
get selected(): Moment {
    return this._selected;
  }

  api = {
    triggerChange: this.emitChange.bind(this)
  };
/**
 * @param {?} timeSelectService
 * @param {?} utilsService
 * @param {?} cd
 */
constructor(public timeSelectService: TimeSelectService,
public utilsService: UtilsService,
public cd: ChangeDetectorRef) {
  }
/**
 * @return {?}
 */
ngOnInit() {
    this.isInited = true;
    this.init();
    this.initValidators();
  }
/**
 * @return {?}
 */
init() {
    this.componentConfig = this.timeSelectService.getConfig(this.config);
    this.selected = this.selected || moment();
    this.inputValueType = this.utilsService.getInputType(this.inputValue, false);
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {
    if (this.isInited) {
      const {minDate, maxDate, minTime, maxTime} = changes;
      this.init();

      if (minDate || maxDate || minTime || maxTime) {
        this.initValidators();
      }
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
writeValue(value: CalendarValue): void {
    this.inputValue = value;

    if (value) {
      const /** @type {?} */ momentValue = this.utilsService
        .convertToMomentArray(value, this.timeSelectService.getTimeFormat(this.componentConfig), false, this.componentConfig.locale)[0];
      if (momentValue.isValid()) {
        this.selected = momentValue;
        this.inputValueType = this.utilsService
          .getInputType(this.inputValue, false);
      }
    }

    this.cd.markForCheck();
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
/**
 * @param {?} _
 * @return {?}
 */
onChangeCallback(_: any) {
  };
/**
 * @param {?} fn
 * @return {?}
 */
registerOnTouched(fn: any): void {
  }
/**
 * @param {?} formControl
 * @return {?}
 */
validate(formControl: FormControl): ValidationErrors | any {
    if (this.minDate || this.maxDate || this.minTime || this.maxTime) {
      return this.validateFn(formControl.value);
    } else {
      return () => null;
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
processOnChangeCallback(value: Moment): CalendarValue {
    return this.utilsService.convertFromMomentArray(
      this.timeSelectService.getTimeFormat(this.componentConfig),
      [value],
      this.componentConfig.returnedValueType || this.inputValueType,
      this.componentConfig.locale
    );
  }
/**
 * @return {?}
 */
initValidators() {
    this.validateFn = this.utilsService.createValidator(
      {
        minDate: this.minDate,
        maxDate: this.maxDate,
        minTime: this.minTime,
        maxTime: this.maxTime
      }, undefined, 'day',
      this.componentConfig.locale);

    this.onChangeCallback(this.processOnChangeCallback(this.selected));
  }
/**
 * @param {?} unit
 * @return {?}
 */
decrease(unit: TimeUnit) {
    this.selected = this.timeSelectService.decrease(this.componentConfig, this.selected, unit);
    this.emitChange();
  }
/**
 * @param {?} unit
 * @return {?}
 */
increase(unit: TimeUnit) {
    this.selected = this.timeSelectService.increase(this.componentConfig, this.selected, unit);
    this.emitChange();
  }
/**
 * @return {?}
 */
toggleMeridiem() {
    this.selected = this.timeSelectService.toggleMeridiem(this.selected);
    this.emitChange();
  }
/**
 * @return {?}
 */
emitChange() {
    this.onChange.emit({date: this.selected, selected: false});
    this.cd.markForCheck();
  }
/**
 * @param {?} time
 * @return {?}
 */
calculateTimeParts(time: Moment) {
    this.hours = this.timeSelectService.getHours(this.componentConfig, time);
    this.minutes = this.timeSelectService.getMinutes(this.componentConfig, time);
    this.seconds = this.timeSelectService.getSeconds(this.componentConfig, time);
    this.meridiem = this.timeSelectService.getMeridiem(this.componentConfig, time);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'dp-time-select',
  template: `
    <ul class="dp-time-select-controls">
      <li class="dp-time-select-control dp-time-select-control-hours">
        <button type="button"
                class="dp-time-select-control-up"
                [disabled]="!showIncHour"
                (click)="increase('hour')">
        </button>
        <span class="dp-time-select-display-hours"
              [innerText]="hours">
        </span>
        <button type="button"
                class="dp-time-select-control-down"
                [disabled]="!showDecHour"
                (click)="decrease('hour')"></button>
      </li>
      <li class="dp-time-select-control dp-time-select-separator"
          [innerText]="componentConfig.timeSeparator">
      </li>
      <li class="dp-time-select-control dp-time-select-control-minutes">
        <button type="button"
                class="dp-time-select-control-up"
                [disabled]="!showIncMinute"
                (click)="increase('minute')"></button>
        <span class="dp-time-select-display-minutes"
              [innerText]="minutes">
        </span>
        <button type="button"
                [disabled]="!showDecMinute" class="dp-time-select-control-down"
                (click)="decrease('minute')"></button>
      </li>
      <ng-container *ngIf="componentConfig.showSeconds">
        <li class="dp-time-select-control dp-time-select-separator"
            [innerText]="componentConfig.timeSeparator">
        </li>
        <li class="dp-time-select-control dp-time-select-control-seconds">
          <button type="button"
                  class="dp-time-select-control-up"
                  [disabled]="!showIncSecond"
                  (click)="increase('second')"></button>
          <span class="dp-time-select-display-seconds"
                [innerText]="seconds">
          </span>
          <button type="button"
                  class="dp-time-select-control-down"
                  [disabled]="!showDecSecond"
                  (click)="decrease('second')"></button>
        </li>
      </ng-container>
      <li class="dp-time-select-control dp-time-select-control-meridiem" *ngIf="!componentConfig.showTwentyFourHours">
        <button type="button"
                class="dp-time-select-control-up"
                [disabled]="!showToggleMeridiem"
                (click)="toggleMeridiem()"></button>
        <span class="dp-time-select-display-meridiem"
              [innerText]="meridiem">
        </span>
        <button type="button"
                class="dp-time-select-control-down"
                [disabled]="!showToggleMeridiem"
                (click)="toggleMeridiem()"></button>
      </li>
    </ul>
  `,
  styles: [`
    dp-time-select {
      display: inline-block;
    }
    dp-time-select .dp-time-select-controls {
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: normal;
      background: #FFFFFF;
    }
    dp-time-select .dp-time-select-control {
      display: inline-block;
      margin: 0 auto;
      vertical-align: middle;
      font-size: inherit;
      letter-spacing: 1px;
    }
    dp-time-select .dp-time-select-control-up,
    dp-time-select .dp-time-select-control-down {
      position: relative;
      display: block;
      width: 24px;
      height: 24px;
      margin: 3px auto;
      cursor: pointer;
      color: #E0E0E0;
    }
    dp-time-select .dp-time-select-control-up::before,
    dp-time-select .dp-time-select-control-down::before {
      position: relative;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      vertical-align: baseline;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    dp-time-select .dp-time-select-control-up::before {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      top: 4px;
    }
    dp-time-select .dp-time-select-control-down::before {
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    dp-time-select .dp-time-select-separator {
      width: 5px;
    }
    dp-time-select.dp-material .dp-time-select-control-up,
    dp-time-select.dp-material .dp-time-select-control-down {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background: transparent;
      border: none;
      outline: none;
      border-radius: 50%;
    }
    dp-time-select.dp-material .dp-time-select-control-up::before,
    dp-time-select.dp-material .dp-time-select-control-down::before {
      left: 0;
    }
    dp-time-select.dp-material .dp-time-select-control-up:hover,
    dp-time-select.dp-material .dp-time-select-control-down:hover {
      background: #E0E0E0;
      color: #FFFFFF;
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TimeSelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeSelectComponent),
      multi: true
    }
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: TimeSelectService, },
{type: UtilsService, },
{type: ChangeDetectorRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'config': [{ type: Input },],
'displayDate': [{ type: Input },],
'minDate': [{ type: Input },],
'maxDate': [{ type: Input },],
'minTime': [{ type: Input },],
'maxTime': [{ type: Input },],
'theme': [{ type: HostBinding, args: ['class', ] },{ type: Input },],
'onChange': [{ type: Output },],
};
}

function TimeSelectComponent_tsickle_Closure_declarations() {
/** @type {?} */
TimeSelectComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TimeSelectComponent.ctorParameters;
/** @type {?} */
TimeSelectComponent.propDecorators;
/** @type {?} */
TimeSelectComponent.prototype.config;
/** @type {?} */
TimeSelectComponent.prototype.displayDate;
/** @type {?} */
TimeSelectComponent.prototype.minDate;
/** @type {?} */
TimeSelectComponent.prototype.maxDate;
/** @type {?} */
TimeSelectComponent.prototype.minTime;
/** @type {?} */
TimeSelectComponent.prototype.maxTime;
/** @type {?} */
TimeSelectComponent.prototype.theme;
/** @type {?} */
TimeSelectComponent.prototype.onChange;
/** @type {?} */
TimeSelectComponent.prototype.isInited;
/** @type {?} */
TimeSelectComponent.prototype.componentConfig;
/** @type {?} */
TimeSelectComponent.prototype._selected;
/** @type {?} */
TimeSelectComponent.prototype.inputValue;
/** @type {?} */
TimeSelectComponent.prototype.inputValueType;
/** @type {?} */
TimeSelectComponent.prototype.validateFn;
/** @type {?} */
TimeSelectComponent.prototype.hours;
/** @type {?} */
TimeSelectComponent.prototype.minutes;
/** @type {?} */
TimeSelectComponent.prototype.seconds;
/** @type {?} */
TimeSelectComponent.prototype.meridiem;
/** @type {?} */
TimeSelectComponent.prototype.showDecHour;
/** @type {?} */
TimeSelectComponent.prototype.showDecMinute;
/** @type {?} */
TimeSelectComponent.prototype.showDecSecond;
/** @type {?} */
TimeSelectComponent.prototype.showIncHour;
/** @type {?} */
TimeSelectComponent.prototype.showIncMinute;
/** @type {?} */
TimeSelectComponent.prototype.showIncSecond;
/** @type {?} */
TimeSelectComponent.prototype.showToggleMeridiem;
/** @type {?} */
TimeSelectComponent.prototype.api;
/** @type {?} */
TimeSelectComponent.prototype.timeSelectService;
/** @type {?} */
TimeSelectComponent.prototype.utilsService;
/** @type {?} */
TimeSelectComponent.prototype.cd;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
