import {CalendarMode} from '../common/types/calendar-mode';
import {IDatePickerDirectiveConfig} from './date-picker-directive-config.model';
import {DatePickerDirectiveService} from './date-picker-directive.service';
import {IDpDayPickerApi} from './date-picker.api';
import {DatePickerComponent} from './date-picker.component';
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  ViewContainerRef
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {CalendarValue} from '../common/types/calendar-value';
import {SingleCalendarValue} from '../common/types/single-calendar-value';
import {INavEvent} from '../common/models/navigation-event.model';
import {UtilsService} from '../common/services/utils/utils.service';
export class DatePickerDirective implements OnInit {
private _config: IDatePickerDirectiveConfig;
private _attachTo: ElementRef | string;
private _theme: string;
private _mode: CalendarMode = 'day';
private _minDate: SingleCalendarValue;
private _maxDate: SingleCalendarValue;
private _minTime: SingleCalendarValue;
private _maxTime: SingleCalendarValue;
private _displayDate: SingleCalendarValue;
/**
 * @return {?}
 */
get config(): IDatePickerDirectiveConfig {
    return this._config;
  }
/**
 * @param {?} config
 * @return {?}
 */
set config(config: IDatePickerDirectiveConfig) {
    this._config = this.service.getConfig(config, this.viewContainerRef.element, this.attachTo);
    this.updateDatepickerConfig();
    this.markForCheck();
  }
/**
 * @return {?}
 */
get attachTo(): ElementRef | string {
    return this._attachTo;
  }
/**
 * @param {?} attachTo
 * @return {?}
 */
set attachTo(attachTo: ElementRef | string) {
    this._attachTo = attachTo;
    this._config = this.service.getConfig(this.config, this.viewContainerRef.element, this.attachTo);
    this.updateDatepickerConfig();
    this.markForCheck();
  }
/**
 * @return {?}
 */
get theme(): string {
    return this._theme;
  }
/**
 * @param {?} theme
 * @return {?}
 */
set theme(theme: string) {
    this._theme = theme;
    if (this.datePicker) {
      this.datePicker.theme = theme;
    }

    this.markForCheck();
  }
/**
 * @return {?}
 */
get mode(): CalendarMode {
    return this._mode;
  }
/**
 * @param {?} mode
 * @return {?}
 */
set mode(mode: CalendarMode) {
    this._mode = mode;
    if (this.datePicker) {
      this.datePicker.mode = mode;
    }

    this.markForCheck();
  }
/**
 * @param {?} minDate
 * @return {?}
 */
set minDate(minDate: SingleCalendarValue) {
    this._minDate = minDate;
    if (this.datePicker) {
      this.datePicker.minDate = minDate;
      this.datePicker.ngOnInit();
    }

    this.markForCheck();
  }
/**
 * @return {?}
 */
get minDate(): SingleCalendarValue {
    return this._minDate;
  }
/**
 * @param {?} maxDate
 * @return {?}
 */
set maxDate(maxDate: SingleCalendarValue) {
    this._maxDate = maxDate;
    if (this.datePicker) {
      this.datePicker.maxDate = maxDate;
      this.datePicker.ngOnInit();
    }

    this.markForCheck();
  }
/**
 * @return {?}
 */
get maxDate(): SingleCalendarValue {
    return this._maxDate;
  }
/**
 * @param {?} minTime
 * @return {?}
 */
set minTime(minTime: SingleCalendarValue) {
    this._minTime = minTime;
    if (this.datePicker) {
      this.datePicker.minTime = minTime;
      this.datePicker.ngOnInit();
    }

    this.markForCheck();
  }
/**
 * @return {?}
 */
get minTime(): SingleCalendarValue {
    return this._minTime;
  }
/**
 * @param {?} maxTime
 * @return {?}
 */
set maxTime(maxTime: SingleCalendarValue) {
    this._maxTime = maxTime;
    if (this.datePicker) {
      this.datePicker.maxTime = maxTime;
      this.datePicker.ngOnInit();
    }

    this.markForCheck();
  }
/**
 * @return {?}
 */
get maxTime(): SingleCalendarValue {
    return this._maxTime;
  }
/**
 * @return {?}
 */
get displayDate(): SingleCalendarValue {
    return this._displayDate;
  }
/**
 * @param {?} displayDate
 * @return {?}
 */
set displayDate(displayDate: SingleCalendarValue) {
    this._displayDate = displayDate;
    this.updateDatepickerConfig();

    this.markForCheck();
  }

   open = new EventEmitter<void>();
   close = new EventEmitter<void>();
   onChange = new EventEmitter<CalendarValue>();
   onGoToCurrent: EventEmitter<void> = new EventEmitter();
   onLeftNav: EventEmitter<INavEvent> = new EventEmitter();
   onRightNav: EventEmitter<INavEvent> = new EventEmitter();
public datePicker: DatePickerComponent;
public api: IDpDayPickerApi;
/**
 * @param {?} viewContainerRef
 * @param {?} elemRef
 * @param {?} componentFactoryResolver
 * @param {?} service
 * @param {?} formControl
 * @param {?} utilsService
 */
constructor(public viewContainerRef: ViewContainerRef,
public elemRef: ElementRef,
public componentFactoryResolver: ComponentFactoryResolver,
public service: DatePickerDirectiveService,
public formControl: NgControl,
public utilsService: UtilsService) {
  }
/**
 * @return {?}
 */
ngOnInit(): void {
    this.datePicker = this.createDatePicker();
    this.api = this.datePicker.api;
    this.updateDatepickerConfig();
    this.attachModelToDatePicker();
    this.datePicker.theme = this.theme;
  }
/**
 * @return {?}
 */
createDatePicker(): DatePickerComponent {
    const /** @type {?} */ factory = this.componentFactoryResolver.resolveComponentFactory(DatePickerComponent);
    return this.viewContainerRef.createComponent(factory).instance;
  }
/**
 * @return {?}
 */
attachModelToDatePicker() {
    if (!this.formControl) {
      return;
    }

    this.datePicker.onViewDateChange(this.formControl.value);

    this.formControl.valueChanges.subscribe((value) => {
      if (value !== this.datePicker.inputElementValue) {
        const /** @type {?} */ strVal = this.utilsService.convertToString(value, this.datePicker.componentConfig.format,
          this.datePicker.componentConfig.locale);
        this.datePicker.onViewDateChange(strVal);
      }
    });

    let /** @type {?} */ setup = true;

    this.datePicker.registerOnChange((value, changedByInput) => {
      if (value) {
        const /** @type {?} */ isMultiselectEmpty = setup && Array.isArray(value) && !value.length;

        if (!isMultiselectEmpty && !changedByInput) {
          this.formControl.control.setValue(this.datePicker.inputElementValue);
        }
      }

      const /** @type {?} */ errors = this.datePicker.validateFn(value);

      if (!setup) {
        this.formControl.control.markAsDirty({
          onlySelf: true
        });
      } else {
        setup = false;
      }

      if (errors) {
        if (errors.hasOwnProperty('format')) {
          const {given} = errors['format'];
          this.datePicker.inputElementValue = given;
          if (!changedByInput) {
            this.formControl.control.setValue(given);
          }
        }

        this.formControl.control.setErrors(errors);
      }
    });
  }
/**
 * @return {?}
 */
onClick() {
    this.datePicker.onClick();
  }
/**
 * @return {?}
 */
onFocus() {
    this.datePicker.inputFocused();
  }
/**
 * @return {?}
 */
private updateDatepickerConfig() {
    if (this.datePicker) {
      this.datePicker.minDate = this.minDate;
      this.datePicker.maxDate = this.maxDate;
      this.datePicker.minTime = this.minTime;
      this.datePicker.maxTime = this.maxTime;
      this.datePicker.mode = this.mode || 'day';
      this.datePicker.displayDate = this.displayDate;
      this.datePicker.config = this.config;
      this.datePicker.open = this.open;
      this.datePicker.close = this.close;
      this.datePicker.onChange = this.onChange;
      this.datePicker.onGoToCurrent = this.onGoToCurrent;
      this.datePicker.onLeftNav = this.onLeftNav;
      this.datePicker.onRightNav = this.onRightNav;

      this.datePicker.init();

      if (this.datePicker.componentConfig.disableKeypress) {
        this.elemRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elemRef.nativeElement.removeAttribute('readonly');
      }
    }
  }
/**
 * @return {?}
 */
markForCheck() {
    if (this.datePicker) {
      this.datePicker.cd.markForCheck();
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  exportAs: 'dpDayPicker',
  providers: [DatePickerDirectiveService],
  selector: '[dpDayPicker]'
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
{type: ElementRef, },
{type: ComponentFactoryResolver, },
{type: DatePickerDirectiveService, },
{type: NgControl, decorators: [{ type: Optional }, ]},
{type: UtilsService, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'config': [{ type: Input, args: ['dpDayPicker', ] },],
'attachTo': [{ type: Input },],
'theme': [{ type: Input },],
'mode': [{ type: Input },],
'minDate': [{ type: Input },],
'maxDate': [{ type: Input },],
'minTime': [{ type: Input },],
'maxTime': [{ type: Input },],
'displayDate': [{ type: Input },],
'open': [{ type: Output },],
'close': [{ type: Output },],
'onChange': [{ type: Output },],
'onGoToCurrent': [{ type: Output },],
'onLeftNav': [{ type: Output },],
'onRightNav': [{ type: Output },],
'onClick': [{ type: HostListener, args: ['click', ] },],
'onFocus': [{ type: HostListener, args: ['focus', ] },],
};
}

function DatePickerDirective_tsickle_Closure_declarations() {
/** @type {?} */
DatePickerDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DatePickerDirective.ctorParameters;
/** @type {?} */
DatePickerDirective.propDecorators;
/** @type {?} */
DatePickerDirective.prototype._config;
/** @type {?} */
DatePickerDirective.prototype._attachTo;
/** @type {?} */
DatePickerDirective.prototype._theme;
/** @type {?} */
DatePickerDirective.prototype._mode;
/** @type {?} */
DatePickerDirective.prototype._minDate;
/** @type {?} */
DatePickerDirective.prototype._maxDate;
/** @type {?} */
DatePickerDirective.prototype._minTime;
/** @type {?} */
DatePickerDirective.prototype._maxTime;
/** @type {?} */
DatePickerDirective.prototype._displayDate;
/** @type {?} */
DatePickerDirective.prototype.open;
/** @type {?} */
DatePickerDirective.prototype.close;
/** @type {?} */
DatePickerDirective.prototype.onChange;
/** @type {?} */
DatePickerDirective.prototype.onGoToCurrent;
/** @type {?} */
DatePickerDirective.prototype.onLeftNav;
/** @type {?} */
DatePickerDirective.prototype.onRightNav;
/** @type {?} */
DatePickerDirective.prototype.datePicker;
/** @type {?} */
DatePickerDirective.prototype.api;
/** @type {?} */
DatePickerDirective.prototype.viewContainerRef;
/** @type {?} */
DatePickerDirective.prototype.elemRef;
/** @type {?} */
DatePickerDirective.prototype.componentFactoryResolver;
/** @type {?} */
DatePickerDirective.prototype.service;
/** @type {?} */
DatePickerDirective.prototype.formControl;
/** @type {?} */
DatePickerDirective.prototype.utilsService;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
