import {Injectable} from '@angular/core';
import * as momentNs from 'jalali-moment';
import {Moment} from 'jalali-moment';

import {UtilsService} from '../common/services/utils/utils.service';
import {DayCalendarService} from '../day-calendar/day-calendar.service';
import {TimeSelectService} from '../time-select/time-select.service';
import {IDayTimeCalendarConfig} from './day-time-calendar-config.model';
const /** @type {?} */ moment = momentNs;

const /** @type {?} */ DAY_FORMAT = 'YYYYMMDD';
const /** @type {?} */ TIME_FORMAT = 'HH:mm:ss';
const /** @type {?} */ COMBINED_FORMAT = DAY_FORMAT + TIME_FORMAT;
export class DayTimeCalendarService {
  readonly DEFAULT_CONFIG: IDayTimeCalendarConfig = {
    locale: 'fa'
  };
/**
 * @param {?} utilsService
 * @param {?} dayCalendarService
 * @param {?} timeSelectService
 */
constructor(private utilsService: UtilsService,
private dayCalendarService: DayCalendarService,
private timeSelectService: TimeSelectService) {
  }
/**
 * @param {?} config
 * @return {?}
 */
getConfig(config: IDayTimeCalendarConfig): IDayTimeCalendarConfig {
    const /** @type {?} */ _config = {
      ...this.DEFAULT_CONFIG,
      ...this.timeSelectService.getConfig(config),
      ...this.dayCalendarService.getConfig(config)
    };

    // moment.locale(config.locale);

    return _config;
  }
/**
 * @param {?} current
 * @param {?} day
 * @param {?} config
 * @return {?}
 */
updateDay(current: Moment, day: Moment, config: IDayTimeCalendarConfig): Moment {
    const /** @type {?} */ time = current ? current : moment();
    let /** @type {?} */ updated = moment.from(day.format(DAY_FORMAT) + time.format(TIME_FORMAT), day.locale(), COMBINED_FORMAT)

    if (config.min) {
      const /** @type {?} */ min = /** @type {?} */(( <Moment>config.min));
      updated = min.isAfter(updated) ? min : updated;
    }

    if (config.max) {
      const /** @type {?} */ max = /** @type {?} */(( <Moment>config.max));
      updated = max.isBefore(updated) ? max : updated;
    }

    return updated;
  }
/**
 * @param {?} current
 * @param {?} time
 * @return {?}
 */
updateTime(current: Moment, time: Moment): Moment {
    const /** @type {?} */ day = current ? current : moment();

    return moment.from(day.format(DAY_FORMAT) + time.format(TIME_FORMAT), day.locale(), COMBINED_FORMAT);
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: UtilsService, },
{type: DayCalendarService, },
{type: TimeSelectService, },
];
}

function DayTimeCalendarService_tsickle_Closure_declarations() {
/** @type {?} */
DayTimeCalendarService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DayTimeCalendarService.ctorParameters;
/** @type {?} */
DayTimeCalendarService.prototype.DEFAULT_CONFIG;
/** @type {?} */
DayTimeCalendarService.prototype.utilsService;
/** @type {?} */
DayTimeCalendarService.prototype.dayCalendarService;
/** @type {?} */
DayTimeCalendarService.prototype.timeSelectService;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
