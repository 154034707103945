import {ECalendarValue} from '../common/types/calendar-value-enum';
import {SingleCalendarValue} from '../common/types/single-calendar-value';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Moment} from 'jalali-moment';
import {CalendarValue} from '../common/types/calendar-value';
import {UtilsService} from '../common/services/utils/utils.service';
import {IDate} from '../common/models/date.model';
import {DayCalendarService} from '../day-calendar/day-calendar.service';
import {TimeSelectService} from '../time-select/time-select.service';
import {IDayTimeCalendarConfig} from './day-time-calendar-config.model';
import {DayTimeCalendarService} from './day-time-calendar.service';
import {DateValidator} from '../common/types/validator.type';
import {DayCalendarComponent} from '../day-calendar/day-calendar.component';
import {INavEvent} from '../common/models/navigation-event.model';
export class DayTimeCalendarComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

   config: IDayTimeCalendarConfig;
   displayDate: SingleCalendarValue;
   minDate: SingleCalendarValue;
   maxDate: SingleCalendarValue;
    theme: string;

   onChange: EventEmitter<IDate> = new EventEmitter();
   onGoToCurrent: EventEmitter<void> = new EventEmitter();
   onLeftNav: EventEmitter<INavEvent> = new EventEmitter();
   onRightNav: EventEmitter<INavEvent> = new EventEmitter();

   dayCalendarRef: DayCalendarComponent;

  isInited: boolean = false;
  componentConfig: IDayTimeCalendarConfig;
  _selected: Moment;
  inputValue: CalendarValue;
  inputValueType: ECalendarValue;
  validateFn: DateValidator;
/**
 * @param {?} selected
 * @return {?}
 */
set selected(selected: Moment) {
    this._selected = selected;
    this.onChangeCallback(this.processOnChangeCallback(selected));
  }
/**
 * @return {?}
 */
get selected(): Moment {
    return this._selected;
  }

  api = {
    moveCalendarTo: this.moveCalendarTo.bind(this)
  };
/**
 * @param {?} dayTimeCalendarService
 * @param {?} utilsService
 * @param {?} cd
 */
constructor(public dayTimeCalendarService: DayTimeCalendarService,
public utilsService: UtilsService,
public cd: ChangeDetectorRef) {
  }
/**
 * @return {?}
 */
ngOnInit() {
    this.isInited = true;
    this.init();
    this.initValidators();
  }
/**
 * @return {?}
 */
init() {
    this.componentConfig = this.dayTimeCalendarService.getConfig(this.config);
    this.inputValueType = this.utilsService.getInputType(this.inputValue, false);
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {
    if (this.isInited) {
      const {minDate, maxDate} = changes;
      this.init();

      if (minDate || maxDate) {
        this.initValidators();
      }
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
writeValue(value: CalendarValue): void {
    this.inputValue = value;

    if (value) {
      this.selected = this.utilsService
        .convertToMomentArray(value, this.componentConfig.format, false, this.componentConfig.locale)[0];
      this.inputValueType = this.utilsService
        .getInputType(this.inputValue, false);
    } else {
      this.selected = null;
    }

    this.cd.markForCheck();
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
/**
 * @param {?} _
 * @return {?}
 */
onChangeCallback(_: any) {
  };
/**
 * @param {?} fn
 * @return {?}
 */
registerOnTouched(fn: any): void {
  }
/**
 * @param {?} formControl
 * @return {?}
 */
validate(formControl: FormControl): ValidationErrors | any {
    if (this.minDate || this.maxDate) {
      return this.validateFn(formControl.value);
    } else {
      return () => null;
    }
  }
/**
 * @param {?} value
 * @return {?}
 */
processOnChangeCallback(value: Moment): CalendarValue {
    return this.utilsService.convertFromMomentArray(
      this.componentConfig.format,
      [value],
      this.componentConfig.returnedValueType || this.inputValueType,
      this.componentConfig.locale
    );
  }
/**
 * @return {?}
 */
initValidators() {
    this.validateFn = this.utilsService.createValidator(
      {
        minDate: this.minDate,
        maxDate: this.maxDate
      }, undefined, 'daytime',
      this.componentConfig.locale);

    this.onChangeCallback(this.processOnChangeCallback(this.selected));
  }
/**
 * @param {?} day
 * @return {?}
 */
dateSelected(day: IDate) {
    this.selected = this.dayTimeCalendarService.updateDay(this.selected, day.date, this.config);
    this.emitChange();
  }
/**
 * @param {?} time
 * @return {?}
 */
timeChange(time: IDate) {
    this.selected = this.dayTimeCalendarService.updateTime(this.selected, time.date);
    this.emitChange();
  }
/**
 * @return {?}
 */
emitChange() {
    this.onChange.emit({date: this.selected, selected: false});
  }
/**
 * @param {?} to
 * @return {?}
 */
moveCalendarTo(to: SingleCalendarValue) {
    if (to) {
      this.dayCalendarRef.moveCalendarTo(to);
    }
  }
/**
 * @param {?} change
 * @return {?}
 */
onLeftNavClick(change: INavEvent) {
    this.onLeftNav.emit(change);
  }
/**
 * @param {?} change
 * @return {?}
 */
onRightNavClick(change: INavEvent) {
    this.onRightNav.emit(change);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'dp-day-time-calendar',
  template: `
    <dp-day-calendar #dayCalendar
                     [config]="componentConfig"
                     [ngModel]="_selected"
                     [theme]="theme"
                     [displayDate]="displayDate"
                     (onSelect)="dateSelected($event)"
                     (onGoToCurrent)="onGoToCurrent.emit()"
                     (onLeftNav)="onLeftNavClick($event)"
                     (onRightNav)="onRightNavClick($event)">
    </dp-day-calendar>
    <dp-time-select #timeSelect
                    [config]="componentConfig"
                    [ngModel]="_selected"
                    (onChange)="timeChange($event)"
                    [theme]="theme">
    </dp-time-select>
  `,
  styles: [`
    dp-day-time-calendar {
      display: inline-block;
    }
    dp-day-time-calendar dp-time-select {
      display: block;
      border-top: 0;
    }
    dp-day-time-calendar.dp-material dp-time-select {
      border-top: 0;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    DayTimeCalendarService,
    DayCalendarService,
    TimeSelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DayTimeCalendarComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DayTimeCalendarComponent),
      multi: true
    }
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: DayTimeCalendarService, },
{type: UtilsService, },
{type: ChangeDetectorRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'config': [{ type: Input },],
'displayDate': [{ type: Input },],
'minDate': [{ type: Input },],
'maxDate': [{ type: Input },],
'theme': [{ type: HostBinding, args: ['class', ] },{ type: Input },],
'onChange': [{ type: Output },],
'onGoToCurrent': [{ type: Output },],
'onLeftNav': [{ type: Output },],
'onRightNav': [{ type: Output },],
'dayCalendarRef': [{ type: ViewChild, args: ['dayCalendar', ] },],
};
}

function DayTimeCalendarComponent_tsickle_Closure_declarations() {
/** @type {?} */
DayTimeCalendarComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DayTimeCalendarComponent.ctorParameters;
/** @type {?} */
DayTimeCalendarComponent.propDecorators;
/** @type {?} */
DayTimeCalendarComponent.prototype.config;
/** @type {?} */
DayTimeCalendarComponent.prototype.displayDate;
/** @type {?} */
DayTimeCalendarComponent.prototype.minDate;
/** @type {?} */
DayTimeCalendarComponent.prototype.maxDate;
/** @type {?} */
DayTimeCalendarComponent.prototype.theme;
/** @type {?} */
DayTimeCalendarComponent.prototype.onChange;
/** @type {?} */
DayTimeCalendarComponent.prototype.onGoToCurrent;
/** @type {?} */
DayTimeCalendarComponent.prototype.onLeftNav;
/** @type {?} */
DayTimeCalendarComponent.prototype.onRightNav;
/** @type {?} */
DayTimeCalendarComponent.prototype.dayCalendarRef;
/** @type {?} */
DayTimeCalendarComponent.prototype.isInited;
/** @type {?} */
DayTimeCalendarComponent.prototype.componentConfig;
/** @type {?} */
DayTimeCalendarComponent.prototype._selected;
/** @type {?} */
DayTimeCalendarComponent.prototype.inputValue;
/** @type {?} */
DayTimeCalendarComponent.prototype.inputValueType;
/** @type {?} */
DayTimeCalendarComponent.prototype.validateFn;
/** @type {?} */
DayTimeCalendarComponent.prototype.api;
/** @type {?} */
DayTimeCalendarComponent.prototype.dayTimeCalendarService;
/** @type {?} */
DayTimeCalendarComponent.prototype.utilsService;
/** @type {?} */
DayTimeCalendarComponent.prototype.cd;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
