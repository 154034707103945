import {Injectable} from '@angular/core';
import {TDrops, TOpens} from '../../types/poistions.type';
export class DomHelper {
/**
 * @param {?} element
 * @param {?} container
 * @param {?} anchor
 * @param {?} drops
 * @return {?}
 */
private static setYAxisPosition(element: HTMLElement, container: HTMLElement, anchor: HTMLElement, drops: TDrops) {
    const /** @type {?} */ anchorRect = anchor.getBoundingClientRect();
    const /** @type {?} */ containerRect = container.getBoundingClientRect();
    const /** @type {?} */ bottom = anchorRect.bottom - containerRect.top;
    const /** @type {?} */ top = anchorRect.top - containerRect.top;

    if (drops === 'down') {
      element.style.top = (bottom + 1 + 'px');
    } else {
      element.style.top = (top - 1 - element.scrollHeight) + 'px';
    }
  }
/**
 * @param {?} element
 * @param {?} container
 * @param {?} anchor
 * @param {?} dimElem
 * @param {?} opens
 * @return {?}
 */
private static setXAxisPosition(element: HTMLElement, container: HTMLElement, anchor: HTMLElement, dimElem: HTMLElement, opens: TOpens) {
    const /** @type {?} */ anchorRect = anchor.getBoundingClientRect();
    const /** @type {?} */ containerRect = container.getBoundingClientRect();
    const /** @type {?} */ left = anchorRect.left - containerRect.left;

    if (opens === 'right') {
      element.style.left = left + 'px';
    } else {
      element.style.left = left - dimElem.offsetWidth + anchor.offsetWidth + 'px';
    }
  }
/**
 * @param {?} el
 * @return {?}
 */
private static isTopInView(el: HTMLElement): boolean {
    const {top} = el.getBoundingClientRect();
    return (top >= 0);
  }
/**
 * @param {?} el
 * @return {?}
 */
private static isBottomInView(el: HTMLElement): boolean {
    const {bottom} = el.getBoundingClientRect();
    return (bottom <= window.innerHeight);
  }
/**
 * @param {?} el
 * @return {?}
 */
private static isLeftInView(el: HTMLElement): boolean {
    const {left} = el.getBoundingClientRect();
    return (left >= 0);
  }
/**
 * @param {?} el
 * @return {?}
 */
private static isRightInView(el: HTMLElement): boolean {
    const {right} = el.getBoundingClientRect();
    return (right <= window.innerWidth);
  }
/**
 * @param {?} config
 * @return {?}
 */
appendElementToPosition(config: IAppendToArgs): void {
    const {container, element} = config;

    if (!container.style.position || container.style.position === 'static') {
      container.style.position = 'relative';
    }

    if (element.style.position !== 'absolute') {
      element.style.position = 'absolute';
    }

    element.style.visibility = 'hidden';

    setTimeout(() => {
      this.setElementPosition(config);

      element.style.visibility = 'visible';
    });
  }
/**
 * @param {?} __0
 * @return {?}
 */
setElementPosition({element, container, anchor, dimElem, drops, opens}: IAppendToArgs) {
    DomHelper.setYAxisPosition(element, container, anchor, 'down');
    DomHelper.setXAxisPosition(element, container, anchor, dimElem, 'right');

    if (drops !== 'down' && drops !== 'up') {
      if (DomHelper.isBottomInView(dimElem)) {
        DomHelper.setYAxisPosition(element, container, anchor, 'down');
      } else if (DomHelper.isTopInView(dimElem)) {
        DomHelper.setYAxisPosition(element, container, anchor, 'up');
      }
    } else {
      DomHelper.setYAxisPosition(element, container, anchor, drops);
    }

    if (opens !== 'left' && opens !== 'right') {
      if (DomHelper.isRightInView(dimElem)) {
        DomHelper.setXAxisPosition(element, container, anchor, dimElem, 'right');
      } else if (DomHelper.isLeftInView(dimElem)) {
        DomHelper.setXAxisPosition(element, container, anchor, dimElem, 'left');
      }
    } else {
      DomHelper.setXAxisPosition(element, container, anchor, dimElem, opens);
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function DomHelper_tsickle_Closure_declarations() {
/** @type {?} */
DomHelper.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DomHelper.ctorParameters;
}


export interface IAppendToArgs {
  container: HTMLElement;
  element: HTMLElement;
  anchor: HTMLElement;
  dimElem: HTMLElement;
  drops: TDrops;
  opens: TOpens;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
